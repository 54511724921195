import React from 'react';
import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';
import useAdverts from '../hooks/useAdverts';
import LeftArrow from 'components/shared/generic/arrow/presentational/Left';
import RightArrow from 'components/shared/generic/arrow/presentational/Right';

const ReloadableLink = (props) => {
    const {isExternal, ...linkProps} = props;
    return isExternal ? (<a {...linkProps} href={String(props.to.pathname)}>{props.children}</a>) :
        <Link {...linkProps}>
            {props.children}
        </Link>
};

const Adverts = ({ list }) => {
    const { currentSlide, handleChange } = useAdverts(list);

    return (
        <div className="adverts-container">
            <div className="adverts-grid">
                {list
                    .slice(currentSlide, currentSlide + 2)
                    .map(({ title, background, link, isExternal }, index) => (
                        <ReloadableLink
                            isExternal={isExternal}
                            key={index}
                            to={{ pathname: link, state: { backRoute: '/' } }}
                        >
                            <img
                                className="item"
                                src={background}
                                alt={`Suits Me Advert ${index + 1}`}
                                onClick={() =>
                                    analytics.sendLinkEvent(
                                        'Adverts Click',
                                        title,
                                    )
                                }
                            />
                        </ReloadableLink>
                    ))}
                <>
                    <LeftArrow
                        onClick={() => handleChange(false)}
                        disabled={currentSlide <= 0}
                    />
                    <RightArrow
                        onClick={() => handleChange(true)}
                        disabled={currentSlide >= list.length - 2}
                    />
                </>
            </div>
        </div>
    );
};

export default Adverts;
