import React, { useEffect } from 'react';

import DirectDebit from '../presentational/DirectDebit';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import { useDispatch, useSelector } from 'react-redux';
import { getDirectDebits } from 'lib/selectors';
import { fetchDirectDebits } from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';

const DirectDebitContainer = () => {
    const dispatch = useDispatch();
    const translate = useTranslate('directDebits');

    useEffect(() => {
        dispatch(fetchDirectDebits());
    }, []);
    const directDebits = useSelector(getDirectDebits);
    const data = Object.values(directDebits);
    return (
        <PageContainer>
            <SectionTitle
                title={translate('titleText')}
                backRoute="/payments"
            />
            <DataCheck data={data} noDataMessage={translate('noDataMessage')}>
                <DirectDebit data={data} />
            </DataCheck>
        </PageContainer>
    );
};

export default DirectDebitContainer;
