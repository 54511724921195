import ForgottenDetails from 'components/auth/forgottenDetails/ForgottenDetails';
import Auth from 'components/auth/main/containers/AuthContainer';
import PasscodeRecoveryContainer from 'components/auth/passcodeRecovery/containers/PasscodeRecoveryContainer';
import PasswordRecoveryContainer from 'components/auth/passwordRecovery/containers/PasswordRecoveryContainer';
import RecoverySuccess from 'components/auth/recoverySuccess/presentational/RecoverySuccess';

export default [
    { path: 'login', component: Auth, pageTitle: 'Login' },
    {
        path: 'forgotten-details',
        component: ForgottenDetails,
        pageTitle: 'Forgotten Details',
    },
    {
        path: 'forgotten-password',
        component: PasswordRecoveryContainer,
        pageTitle: 'Forgotten Password',
    },
    {
        path: 'forgotten-security-code',
        component: PasscodeRecoveryContainer,
        pageTitle: 'Forgotten Security Code',
    },
    {
        path: 'recovery-success',
        component: RecoverySuccess,
        pageTitle: 'Password Recovery Success',
    },
];
