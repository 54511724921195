export const languageCodes = {
    ENGLISH: 'eng',
    GERMAN: 'deu',
    FRENCH: 'fra',
    BULGARIAN: 'bul',
    ROMANIAN: 'ron',
    POLISH: 'pol',
};

export const languageCodesWords = {
    eng: 'English',
    // deu: 'Deutsch',
    // fra: 'Français',
    bul: 'български',
    ron: 'Română',
    pol: 'Polski',
};

export const zendeskLanguageCodes = {
    [languageCodes.ENGLISH]: 'en-gb',
    [languageCodes.GERMAN]: 'de',
    [languageCodes.FRENCH]: 'fr',
    [languageCodes.BULGARIAN]: 'bg',
    [languageCodes.ROMANIAN]: 'ro',
    [languageCodes.POLISH]: 'pl',
};

export const transferWiseLanguageCodes = {
    [languageCodes.ENGLISH]: 'en',
    [languageCodes.GERMAN]: 'de',
    [languageCodes.FRENCH]: 'fr',
    [languageCodes.BULGARIAN]: 'bg',
    [languageCodes.ROMANIAN]: 'ro',
    [languageCodes.POLISH]: 'pl',
};

export const languageCodeOptions = [
    { label: languageCodesWords.eng, subLabel: null, value: languageCodes.ENGLISH },
    // { label: languageCodesWords.deu, subLabel: 'German', value: languageCodes.GERMAN },
    // { label: languageCodesWords.fra, subLabel: 'French', value: languageCodes.FRENCH },
    { label: languageCodesWords.bul, subLabel: 'Bulgarian', value: languageCodes.BULGARIAN },
    { label: languageCodesWords.ron, subLabel: 'Romanian', value: languageCodes.ROMANIAN },
    { label: languageCodesWords.pol, subLabel: 'Polish', value: languageCodes.POLISH },
];
