import { createReducer } from '@reduxjs/toolkit';

import { setGenericError, clearGenericError, testErrorsRequest, testErrorsFailure, testAPIErrorsRequest, testAPIErrorsFailure } from '../actions';

const initialState = {
    genericError: null,
};

export default createReducer(initialState, {
    [setGenericError]: handleSetGenericError,
    [clearGenericError]: handleClearGenericError,
    [testErrorsRequest]: handleTestErrorsRequest,
    [testErrorsFailure]: handleTestErrorsFailure,
    [testAPIErrorsRequest]: handleTestErrorsRequest,
    [testAPIErrorsFailure]: handleTestErrorsFailure,
});

function handleSetGenericError(state, action) {
    state.genericError = action.payload;
}

function handleClearGenericError(state) {
    state.genericError = null;
}

function handleTestErrorsRequest(state) {
    state.genericError = null;
}

function handleTestErrorsFailure(state, action) {
    state.genericError = action.payload;
}
