import { createReducer } from '@reduxjs/toolkit';

import {
    createTransactionRequest,
    createTransactionSuccess,
    createTransactionFailure,
    fetchActivityRequest,
    fetchActivitySuccess,
    fetchActivityFailure,
    updateSelectedAccountSuccess,
    fetchPendingTransactionsRequest,
    fetchPendingTransactionsSuccess,
    fetchPendingTransactionsFailure,
} from '../actions';

const initialState = {
    isPosting: false,
    postSuccess: false,
    postError: null,
    isFetching: false,
    error: null,
    isLastPage: false,
    pageNumber: 1,
    items: [],
    isFetchingPending: false,
    pendingFetchError: null,
    pendingItems: [],
    totalAmountPending: null,
    totalTransactionsPending: null,
};

export default createReducer(initialState, {
    [createTransactionRequest]: handlePostRequest,
    [createTransactionFailure]: handlePostFailure,
    [createTransactionSuccess]: handlePostSuccess,
    [fetchActivityRequest]: handleFetchRequest,
    [fetchActivityFailure]: handleFetchFailure,
    [fetchActivitySuccess]: handleFetchSuccess,
    [updateSelectedAccountSuccess]: handleUpdateSelectedAccountSuccess,
    [fetchPendingTransactionsRequest]: handleFetchPendingTransactionsRequest,
    [fetchPendingTransactionsSuccess]: handleFetchPendingTransactionsSuccess,
    [fetchPendingTransactionsFailure]: handleFetchPendingFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.isLastPage = action.payload.isLastPage;

    if (action.payload.isFirstPage) {
        state.pageNumber = 1;
        state.items = action.payload.items;
    } else {
        state.pageNumber += 1;
        state.items = state.items.concat(action.payload.items);
    }
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleUpdateSelectedAccountSuccess(state) {
    state.pageNumber = 1;
    state.items = [];
}

function handleFetchPendingTransactionsRequest(state) {
    state.isFetchingPending = true;
    state.pendingFetchError = null;
}

function handleFetchPendingTransactionsSuccess(state, action) {
    state.isFetchingPending = false;
    state.pendingItems = action.payload.items;
    state.totalAmountPending = action.payload.totalAmountPending;
    state.totalTransactionsPending = action.payload.totalTransactionsPending;
}

function handleFetchPendingFailure(state, action) {
    state.isFetchingPending = false;
    state.pendingFetchError = action.payload;
}