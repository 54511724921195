import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearFormError, validatePassCode } from '../actions';
import { getAuthError, getAuthIsPosting, getFormError } from '../selectors';
import { useForm, usePrevious } from '../utils';

const numberNameLookup = {
    [1]: 'first',
    [2]: 'second',
    [3]: 'third',
    [4]: 'fourth',
};

export default function useValidatePassCode() {
    const dispatch = useDispatch();
    const isPosting = useSelector(getAuthIsPosting);
    const error = useSelector(getAuthError);
    const formError = useSelector(getFormError);

    const [num1, num2] = useMemo(() => {
        const number1 = Math.floor(Math.random() * 4) + 1;
        let number2 = Math.floor(Math.random() * 4) + 1;

        while (number1 === number2) {
            number2 = Math.floor(Math.random() * 4) + 1;
        }

        return [number1, number2].sort((a, b) => a - b);
    }, []);

    const [form, handleChange, resetForm] = useForm({ values: '' });
    const prevProps = usePrevious({ formError, form });

    function handleSubmit(captchaToken) {
        const values = [null, null, null, null];
        values[num1 - 1] = form.values.charAt(0);
        values[num2 - 1] = form.values.charAt(1);

        dispatch(validatePassCode({ values, captchaToken }));
    }

    useEffect(() => {
        if (formError && !prevProps.formError) {
            resetForm({ values: '' });
        }
    }, [formError, prevProps.formError]); // Clear passcode if validation fails

    useEffect(() => {
        if (formError && form.values.length && !prevProps.form.values.length)
            dispatch(clearFormError());
    }, [dispatch, formError, form.values, prevProps.form.values]); // Clear form error when first digit is entered

    const description = `Please enter the ${numberNameLookup[num1]} and ${numberNameLookup[num2]} characters of your security code.`;

    return {
        form,
        handleChange,
        handleSubmit,
        description,
        isPosting,
        error,
    };
}
