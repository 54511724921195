import { createReducer } from '@reduxjs/toolkit';

import { cardSatusValues } from '../constants';
import {
    freezeCardRequest,
    freezeCardSuccess,
    freezeCardFailure,
    defrostCardRequest,
    defrostCardSuccess,
    defrostCardFailure,
    reissueCardRequest,
    reissueCardSuccess,
    reissueCardFailure,
    issueCardRequest,
    issueCardSuccess,
    issueCardFailure,
    endCardRequest,
    endCardFailure,
    endCardSuccess,
    activateCardRequest,
    activateCardFailure,
    activateCardSuccess,
    fetchCardsRequest,
    fetchCardsSuccess,
    fetchCardsFailure,
    fetchPinRequest,
    fetchPinSuccess,
    fetchPinFailure,
    clearPinData,
    updateSelectedAccountSuccess,
} from '../actions';

const initialState = {
    isPosting: false,
    postSuccess: false,
    isFetching: false,
    isFetchingPin: false,
    error: null,
    cards: {},
    pin: null,
    pinError: null,
};

export default createReducer(initialState, {
    [freezeCardRequest]: handleFreezeCardRequest,
    [freezeCardSuccess]: handlePostSuccess,
    [freezeCardFailure]: handleFreezeCardFailure,
    [defrostCardRequest]: handleDefrostCardRequest,
    [defrostCardSuccess]: handlePostSuccess,
    [defrostCardFailure]: handleDefrostCardFailure,
    [reissueCardRequest]: handleReissueCardRequest,
    [reissueCardSuccess]: handlePostSuccess,
    [reissueCardFailure]: handleReissueCardFailure,
    [issueCardRequest]: handlePostRequest,
    [issueCardSuccess]: handlePostSuccess,
    [issueCardFailure]: handlePostFailure,
    [endCardRequest]: handleEndCardRequest,
    [endCardSuccess]: handlePostSuccess,
    [endCardFailure]: handleEndCardFailure,
    [activateCardRequest]: handleActivateCardRequest,
    [activateCardSuccess]: handlePostSuccess,
    [activateCardFailure]: handleActivateCardFailure,
    [fetchCardsRequest]: handleFetchCardsRequest,
    [fetchCardsSuccess]: handleFetchCardsSuccess,
    [fetchCardsFailure]: handleFetchCardsFailure,
    [fetchPinRequest]: handleFetchPinRequest,
    [fetchPinSuccess]: handleFetchPinSuccess,
    [fetchPinFailure]: handleFetchPinFailure,
    [clearPinData]: handleClearPinData,
    [updateSelectedAccountSuccess]: state => {
        state.cards = {};
    },
});

function handleFetchCardsRequest(state) {
    state.isFetching = true;
}

function handleFetchCardsSuccess(state, action) {
    state.isFetching = false;

    state.cards = action.payload.reduce((acc, c) => {
        acc[c.cardSerial] = c;
        return acc;
    }, {});
}

function handleFetchCardsFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchPinRequest(state) {
    state.isFetchingPin = true;
    state.pinError = null;
    state.pin = null;
}

function handleFetchPinSuccess(state, action) {
    state.isFetchingPin = false;
    state.pin = action.payload;
}

function handleFetchPinFailure(state, action) {
    state.isFetchingPin = false;
    state.pinError = action.payload;
}

function handleClearPinData(state) {
    state.pinError = null;
    state.pin = null;
}

function handleFreezeCardRequest(state, action) {
    state.postSuccess = false;
    state.isPosting = true;
    state.error = null;

    state.cards[action.payload.cardSerial].status = cardSatusValues.BLOCKED;
}

function handleFreezeCardFailure(state, action) {
    state.isPosting = false;

    state.cards[action.payload.cardSerial].status = cardSatusValues.ACTIVE;
}

function handleDefrostCardRequest(state, action) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;

    state.cards[action.payload.cardSerial].status = cardSatusValues.ACTIVE;
}

function handleDefrostCardFailure(state, action) {
    state.isPosting = false;

    state.cards[action.payload.cardSerial].status = cardSatusValues.BLOCKED;
}

function handleReissueCardRequest(state, action) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;

    state.cards[action.payload.cardSerial].status = cardSatusValues.ISSUED;
}

function handleReissueCardFailure(state, action) {
    state.isPosting = false;

    state.cards[action.payload.cardSerial].status = action.payload.prevStatus;
}

function handleEndCardRequest(state, action) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;

    state.cards[action.payload.cardSerial].status = action.payload.newStatus;
}

function handleEndCardFailure(state, action) {
    state.isPosting = false;

    state.cards[action.payload.cardSerial].status = cardSatusValues.BLOCKED;
}

function handleActivateCardRequest(state, action) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;

    state.cards[action.payload.cardSerial].status = cardSatusValues.ACTIVE;
}

function handleActivateCardFailure(state, action) {
    state.isPosting = false;

    state.cards[action.payload.cardSerial].status = cardSatusValues.ISSUED;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}