import { createAction } from '@reduxjs/toolkit';

import { api } from '../utils';
import { TW_ACCOUNT_STATES } from '../constants';

const {
    UNKNOWN,
    READY,
    CONFLICT,
    ERROR,
    DOES_NOT_EXIST,
    CREATING,
    LINKING_EXISTING,
} = TW_ACCOUNT_STATES;

export const twSetAccountState = createAction('TW_SET_ACCOUNT_STATUS');
export const twFetchAccountStatusRequest = createAction('TW_FETCH_ACCOUNT_STATUS_REQUEST');
export const twFetchAccountStatusFailure = createAction('TW_FETCH_ACCOUNT_STATUS_FAILURE');

export const twCheckAccountExists = () => async dispatch => {
    dispatch(twFetchAccountStatusRequest());
    dispatch(twSetAccountState(UNKNOWN));

    try {
        const { data } = await api.get('/transferwise/user/exists');

        const accountState = data.exists ? READY : DOES_NOT_EXIST;
        return dispatch(twSetAccountState(accountState));
    } catch ({ response }) {
        return dispatch(twSetAccountState(ERROR));
    }
};

export const twCreateAccount = () => async dispatch => {
    dispatch(twSetAccountState(CREATING));

    try {
        await api.post('/transferwise/user');
        return dispatch(twSetAccountState(READY));
    } catch ({ response }) {
        if (response && response.status === 409) {
            return dispatch(twSetAccountState(CONFLICT));
        }

        return dispatch(twSetAccountState(ERROR));
    }
};

export const twLinkExistingUser = postBody => async dispatch => {
    dispatch(twSetAccountState(LINKING_EXISTING));

    try {
        await api.post('/transferwise/user/existing', postBody);

        return dispatch(twSetAccountState(READY));
    } catch (e) {
        return dispatch(twSetAccountState(ERROR));
    }
};
