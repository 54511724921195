import React from 'react';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import InfiniteScroll from 'components/shared/infiniteScroll/presentational/InfiniteScroll';

const PayeeInfiniteScroll = ({ data, onPayeeClick, last, handleFetchPage }) => {
    const ref = useInfiniteScroll(handleFetchPage);

    return (
        <div className="uk-payee-container">
            <InfiniteScroll
                className="uk-payee-list-container"
                ref={ref}
                last={last}
                size="small"
            >
                {Object.values(data).map((payee, index) => {
                    return (
                        <div
                            onClick={() => onPayeeClick(payee.id)}
                            key={index}
                            className="uk-payee-item clickable"
                        >
                            <div className="item-left">
                                <h1 className="uk-payee-name">
                                    {payee.payeeName}
                                </h1>
                                <div className="uk-payee-account">
                                    <p>{payee.accountNo}</p>
                                    <span>|</span>
                                    <p>{payee.sortCode}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default PayeeInfiniteScroll;
