import { createAction } from '@reduxjs/toolkit';

export const addFieldError = createAction('ADD_FIELD_ERROR');
export const removeFieldError = createAction('REMOVE_FIELD_ERROR');
export const clearFieldErrors = createAction('CLEAR_FIELD_ERRORS');
export const setFieldErrors = createAction('SET_FIELD_ERRORS');
export const setErrorsVisible = createAction('SET_ERRORS_VISIBLE');
export const addGenericFormError = createAction('GET_GENERIC_FORM_ERROR');
export const clearFormError = createAction('clearFormError');

export const showFieldErrors = () => dispatch => {
    dispatch(setErrorsVisible(true));

    setTimeout(() => {
        dispatch(setErrorsVisible(false));
    }, 1000);
};
