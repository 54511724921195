import React from 'react';
import { useDispatch } from 'react-redux';
import { reissueCard } from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';

import { useForm } from 'lib/utils';
import StatusCardModal from '../presentational/StatusCardModal';

const StatusCardModalContainer = ({ hideModal, cardSerial, account }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cards.orderReplacementModal');

    const options = [
        { value: 'LOST', label: translate('lost') },
        { value: 'DAMAGED', label: translate('damaged') },
        { value: 'STOLEN', label: translate('stolen') },
    ];

    const [form, handleChange] = useForm({
        status: '',
    });

    const handleClose = () => hideModal();

    const handleSubmit = () => {
        dispatch(reissueCard(cardSerial, form.status));
        handleClose();
    };

    return (
        <div className="status-card-modal">
            <StatusCardModal
                {...form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                productType={account.productType}
                options={options}
            />
        </div>
    );
};

export default StatusCardModalContainer;
