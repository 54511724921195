import { createReducer } from '@reduxjs/toolkit';

import {
    fetchZendeskArticlesRequest,
    fetchZendeskArticlesSuccess,
    fetchZendeskArticlesFailure,
    fetchZendeskCategoriesRequest,
    fetchZendeskCategoriesSuccess,
    fetchZendeskCategoriesFailure,
} from '../actions';
import { convertArrToObj } from '../utils';

const initialState = {
    isFetching: false,
    error: null,
    articles: {},
    categories: {},
};

export default createReducer(initialState, {
    [fetchZendeskArticlesRequest]: handleFetchRequest,
    [fetchZendeskArticlesSuccess]: handleFetchArticlesSuccess,
    [fetchZendeskArticlesFailure]: handleFailure,
    [fetchZendeskCategoriesRequest]: handleFetchRequest,
    [fetchZendeskCategoriesSuccess]: handleFetchCategoriesSuccess,
    [fetchZendeskCategoriesFailure]: handleFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchArticlesSuccess(state, action) {
    state.isFetching = false;
    state.articles = { ...state.articles, ...convertArrToObj(action.payload) };
}

function handleFetchCategoriesSuccess(state, action) {
    state.isFetching = false;
    state.categories = { ...state.categories, ...convertArrToObj(action.payload) };
}

function handleFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}
