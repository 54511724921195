import { createReducer } from '@reduxjs/toolkit';
import {
    postLoginStepRequest,
    postLoginStepSuccess,
    postLoginStepFailure,
    postForgotPasswordRequest,
    postForgotPasswordSuccess,
    postForgotPasswordFailure,
    logout,
    updateSelectedAccountSuccess,
    postForgotPasscodeRequest,
    postForgotPasscodeSuccess,
    postForgotPasscodeFailure,
} from '../actions';

const initialState = {
    isPosting: false,
    postSuccess: false,
    postSuccessToken: null,
    error: null,
    loginStep: null,
};

export default createReducer(initialState, {
    [postLoginStepRequest]: handlePostRequest,
    [postLoginStepSuccess]: handleLoginStepSuccess,
    [postForgotPasswordRequest]: handlePostRequest,
    [postForgotPasswordSuccess]: handlePostSuccess,
    [postForgotPasswordFailure]: handleFailure,
    [postForgotPasscodeRequest]: handlePostRequest,
    [postForgotPasscodeSuccess]: handlePostSuccess,
    [postForgotPasscodeFailure]: handleFailure,
    [postLoginStepFailure]: handleFailure,
    [logout]: handleLogout,
    [updateSelectedAccountSuccess]: handleUpdateAccountSuccess,
});

function handlePostRequest(state) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handleLoginStepSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.postSuccessToken = action.payload.token;
    state.loginStep = action.payload.nextStep;
}

function handleUpdateAccountSuccess(state, action) {
    state.postSuccessToken = action.payload.token;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}

function handleLogout(state) {
    state.loginStep = null;
    state.error = null;
    state.postSuccess = false;
}
