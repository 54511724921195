import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { languageCodes } from '../languages/constants';
import { getLanguage } from '../selectors/languages';

import languagePacks from './languagePacks';

function useTranslate(groupPath) {
    const language = useSelector(getLanguage) || languageCodes.ENGLISH;

    const initialPack = languagePacks[language] || languagePacks[languageCodes.ENGLISH];

    const group = useMemo(
        () => groupPath.split('.').reduce((acc, part) => acc[part], initialPack),
        [language, groupPath],
    );

    const translate = (path, params = {}) => {
        const text = path.split('.').reduce((acc, part) => {
            if (!!acc && !!part) {
                return acc[part];
            }
        }, group);

        return Object.entries(params).reduce(
            (acc = '', [paramName, paramVal]) => acc.replace(`{{${paramName}}}`, paramVal),
            text,
        );
    };

    return translate;
}

export default useTranslate;
