import { createAction } from '@reduxjs/toolkit';

import { api, encodeQueryData, handleErrors } from '../utils';

//  Search Address
export const addressLookupSearchRequest = createAction('addressLookupSearchRequest');
export const addressLookupSearchSuccess = createAction('addressLookupSearchSuccess');
export const addressLookupSearchFailure = createAction('addressLookupSearchFailure');

//  Search Address
export const AddressLookup =
    ({ searchTerm, postCodeId, limit }) =>
    async dispatch => {
        dispatch(addressLookupSearchRequest());

        const params = encodeQueryData({
            ...(searchTerm && { searchTerm }),
            ...(postCodeId && { postCodeId }),
            ...(limit && { limit }),
        });

        try {
            const { data } = await api.get(`address-lookup/search?${params}`);
            return dispatch(addressLookupSearchSuccess(data.items));
        } catch (e) {
            return handleErrors(dispatch, addressLookupSearchFailure, e, true);
        }
    };

// Retrieve Single Address
export const addressLookupSingleRequest = createAction('addressLookupSingleRequest');
export const addressLookupSingleSuccess = createAction('addressLookupSingleSuccess');
export const addressLookupSingleFailure = createAction('addressLookupSingleFailure');

// Retrieve Single Address
// Address Id will look something like this: GB|RM|A|52067001
export const AddressLookupSingle = addressId => async dispatch => {
    dispatch(addressLookupSingleRequest());

    const params = encodeQueryData({ addressId });

    try {
        const { data } = await api.get(`address-lookup/single?${params}`);

        return dispatch(addressLookupSingleSuccess(data.items));
    } catch (e) {
        return handleErrors(dispatch, addressLookupSingleFailure, e, true);
    }
};
