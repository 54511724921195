import { createAction } from '@reduxjs/toolkit';
import { api, handleErrors } from '../utils';

export const setLanguageCode = createAction('setLanguageCode');

export const updateLanguageCodeRequest = createAction('updateLanguageCodeRequest');
export const updateLanguageCodeSuccess = createAction('updateLanguageCodeSuccess');
export const updateLanguageCodeFailue = createAction('updateLanguageCodeFailue');

export const updateLanguageCode = languageCode => async dispatch => {
    dispatch(updateLanguageCodeRequest());

    try {
        await api.patch(`account/language?languageCode=${languageCode}`);

        dispatch(updateLanguageCodeSuccess());
    } catch (e) {
        handleErrors(dispatch, updateLanguageCodeFailue, e, true);
    }
};
