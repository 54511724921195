import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

export const fetchPricePlansRequest = createAction('fetchPricePlansRequest');
export const fetchPricePlansSuccess = createAction('fetchPricePlansSuccess');
export const fetchPricePlansFailure = createAction('fetchPricePlansFailure');

export const fetchPricePlans = languageCode => async dispatch => {
    dispatch(fetchPricePlansRequest());

    try {
        const { data } = await api.get(`price-plans?languageCode=${languageCode}`);

        dispatch(fetchPricePlansSuccess(data));
    } catch (e) {
        handleErrors(dispatch, fetchPricePlansFailure, e, true);
    }
};
