import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

export const fetchFeesRequest = createAction('fetchFeesRequest');
export const fetchFeesSuccess = createAction('fetchFeesSuccess');
export const fetchFeesFailure = createAction('fetchFeesFailure');

export const fetchFees = () => async dispatch => {
    dispatch(fetchFeesRequest());

    try {
        const { data } = await api.get('/fees');

        dispatch(fetchFeesSuccess(data));
    } catch (e) {
        handleErrors(dispatch, fetchFeesFailure, e, true);
    }
};
