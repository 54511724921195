import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllFees,
    getFieldErrors,
    getFormError,
    getSelectedPayee,
    getStandingOrderIsPosting,
} from 'lib/selectors';
import { useMakeStandingOrder } from 'lib/hooks';
import {
    PAYMENT_STANDING_ORDER_SUCCESS,
    PAYMENT_STANDING_ORDER,
    PAYMENT_STANDING_ORDER_PAYEE,
} from 'constants/shared/routes';
import { hideModal, showModal } from 'actions/modal';
import {
    CONFIRM_AND_SEND_MODAL,
    STANDING_ORDER_FEE_MODAL,
} from 'constants/shared/modalTypes';
import { dispatchHideModal } from 'utils/modals';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import StandingOrderForm from '../presentational/StandingOrderForm';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { usePrevious } from 'lib/utils';

const StandingOrderFormContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPayee = useSelector(getSelectedPayee);
    const isInternal = selectedPayee.isSuitsMeCustomer;
    const allFees = useSelector(getAllFees);

    const handleClose = useCallback(() => dispatch(hideModal()), []);

    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);

    const prevState = usePrevious({fieldErrors, formError});
    
    useEffect(() => {
        const isNewFormError = formError && !prevState.formError;
        const isNewFieldErrors = Object.keys(fieldErrors).length > Object.keys(prevState.fieldErrors).length;

        if (isNewFormError || isNewFieldErrors){
            handleClose();
        }
    }, [fieldErrors, formError, prevState, handleClose])

    const {
        standingOrderSetupFee,
        internalTransactionFee,
        externalTransactionFee,
    } = allFees;

    const {
        form,
        fields,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useMakeStandingOrder(onSubmit);

    const handleConfirmModal = () => {
        dispatch(
            showModal({
                type: STANDING_ORDER_FEE_MODAL,
                props: {
                    fee: standingOrderSetupFee,
                    recurranceFee: isInternal
                        ? internalTransactionFee
                        : externalTransactionFee,
                    handleSubmit: handleShowStandingOrderConfirmModal,
                    closeModal,
                },
            }),
        );
    };

    const handleShowStandingOrderConfirmModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_AND_SEND_MODAL,
                props: {
                    form,
                    selectedPayee,
                    postingSelector: getStandingOrderIsPosting,
                    error,
                    type: 'standing-order',
                    handleSubmit,
                },
            }),
        );
    };

    const closeModal = useCallback(() => {
        dispatch(hideModal());
    }, []);

    function onSubmit() {
        dispatchHideModal(dispatch);
        history.push(PAYMENT_STANDING_ORDER_SUCCESS, {
            data: {
                amount: form.amount,
                recipient: selectedPayee.payeeName,
            },
        });
    }

    useEffect(() => {
        if (!selectedPayee) {
            history.push(PAYMENT_STANDING_ORDER);
        }
    }, []);

    return (
        <PageContainer className="standing-order-form-container">
            <SectionTitle
                title={selectedPayee && selectedPayee.payeeName}
                backRoute={PAYMENT_STANDING_ORDER_PAYEE}
                additionalText={`${selectedPayee &&
                    selectedPayee.accountNo} | ${selectedPayee &&
                    selectedPayee.sortCode}`}
            />
            <StandingOrderForm
                {...form}
                fields={fields}
                handleChange={handleChange}
                handleSubmit={handleConfirmModal}
                isPosting={isPosting}
            />
        </PageContainer>
    );
};

export default StandingOrderFormContainer;
