export const getAccountIsFetching = state => state.accountReducer.isFetching;
export const getAccountError = state => state.accountReducer.error;
export const getAccount = state => state.accountReducer.account;
export const getAccountPostSuccess = state => state.accountReducer.postSuccess;
export const getAccountIsPosting = state => state.accountReducer.isPosting;
export const getAccountPostError = state => state.accountReducer.postError;

export const getIsFetchingAccounts = state => state.accountReducer.isFetchingAccounts;
export const getAccountsList = state => state.accountReducer.accountsList;
export const getShowAccountBlockedModal = state => state.accountReducer.showAccountBlockedModal;
