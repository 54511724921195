import { reverseEnum } from '../utils/generic';

export const accountRoles = {
    Essential: 'SUITS_ESS_ACC_GBP',
    Premium: 'SUITS_PREM_ACC_GBP',
    PremiumPlus: 'SUITS_PREMP_ACC_GBP',
};

export const accountRolesLookup = {
    [accountRoles.Essential]: 'Essential',
    [accountRoles.Premium]: 'Premium',
    [accountRoles.PremiumPlus]: 'Premium Plus',
}

export const subscriptionMakeBalancesLookup = {
    [accountRoles.Essential]: 500000,
    [accountRoles.Premium]: 750000,
    [accountRoles.PremiumPlus]: 1000000,
};
