import { useSelector } from 'react-redux';
import { getAccount } from '../selectors/account';

const useCheckAccountStatus = () => {
    const account = useSelector(getAccount) || {};

    const isDormant = !!account.isDormant;

    return [isDormant];
};

export default useCheckAccountStatus;
