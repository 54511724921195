import { createReducer } from '@reduxjs/toolkit';

import { logout } from 'lib/actions';
import { setJwtHeader } from 'lib/utils';
import { clearRedirectRoute } from 'actions/redirect';

const initialState = {
    route: null,
};

export default createReducer(initialState, {
    [logout]: handleLogout,
    [clearRedirectRoute]: handleClear,
});

function handleLogout(state) {
    setJwtHeader(null);
    localStorage.setItem('token', null);
    state.route = '/auth/login';
}

function handleClear(state) {
    state.route = null;
}
