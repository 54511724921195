import React from 'react';
import { useDispatch } from 'react-redux';
import { activateAccount } from 'lib/actions';
import { useForm } from 'lib/utils';
import Activation from '../presentational/Activation';

const ActivationContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({
        activationCode: '',
    });

    const handleSubmit = () => {
        dispatch(activateAccount(form));
    };

    return (
        <Activation
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};

export default ActivationContainer;
