import React from 'react';
import Box from 'components/shared/generic/box/presentational/Box';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';
import LoginTemplate from 'components/shared/layout/loginTemplate/presentational/LoginTemplate';
import { USERNAME_REMINDER_LINK } from 'config/index';
import { useHistory } from 'react-router-dom';

const ForgottenDetails = () => {
    const translate = useTranslate('forgottenDetails'); // TODO

    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <LoginTemplate>
            <Box>
                <SectionTitle title={translate('title')} secondary />
                <Button
                    href={USERNAME_REMINDER_LINK}
                    size="full"
                >
                    {translate('usernameReminder')}
                </Button>
                <Button to="/auth/forgotten-password" size="full" target="">
                    {translate('resetPassword')}
                </Button>
                <Button to="/auth/forgotten-security-code" size="full" target="">
                    {translate('resetPasscode')}
                </Button>
                <Button className="invisible-backdrop" size="full" onClick={handleGoBack}>
                    {translate('cancel')}
                </Button>
            </Box>
        </LoginTemplate>
    );
};

export default ForgottenDetails;
