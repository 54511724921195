import { createReducer } from '@reduxjs/toolkit';

import {
    fetchPersonalDetailsRequest,
    fetchPersonalDetailsSuccess,
    fetchPersonalDetailsFailure,
    updatePersonalDetailsRequest,
    updatePersonalDetailsSuccess,
    updatePersonalDetailsFailure,
    updateSelectedAccountSuccess,
} from '../actions';

const initialState = {
    isFetching: false,
    isPosting: false,
    error: null,
    details: {},
    postSuccess: false,
};

export default createReducer(initialState, {
    [fetchPersonalDetailsRequest]: handleFetchRequest,
    [fetchPersonalDetailsSuccess]: handleFetchSuccess,
    [fetchPersonalDetailsFailure]: handleFailure,
    [updatePersonalDetailsRequest]: handlePostRequest,
    [updatePersonalDetailsSuccess]: handlePostSuccess,
    [updatePersonalDetailsFailure]: handleFailure,
    [updateSelectedAccountSuccess]: state => {
        state.details = {};
    },
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.details = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.error = null;
    state.postSuccess = false;
}

function handlePostSuccess(state, action) {
    state.isPosting = false;
    state.details = action.payload;
    state.postSuccess = true;
}

function handleFailure(state, action) {
    state.isFetching = false;
    state.isPosting = false;
    state.error = action.payload;
}
