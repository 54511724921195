import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm, usePrevious } from '../utils';
import { updatePersonalDetails } from '../actions';
import {
    getPersonalDetails,
    getPersonalDetailsPostSuccess,
    getPersonalDetailsIsPosting,
    getPersonalDetailsError,
} from '../selectors';

const useEditPersonalDetails = onPostSuccess => {
    const dispatch = useDispatch();

    const details = useSelector(getPersonalDetails);
    const postSuccess = useSelector(getPersonalDetailsPostSuccess);
    const isPosting = useSelector(getPersonalDetailsIsPosting);
    const error = useSelector(getPersonalDetailsError);

    const initialFormData = {
        email: details.email,
        mobile: details.mobile,
        buildingNo: details.buildingNo,
        buildingName: details.buildingName,
        streetAddress: details.streetAddress,
        town: details.town,
        postcode: details.postcode,
        country: details.country,
        // primaryTaxResidency: details.primaryTaxResidency,
    };

    const [formData, onChange, setFormData] = useForm(initialFormData);

    const handleSwitchClick = ({ target }) => {
        if (isPosting) return;
        const isChecked = target.checked;
        onChange(target.name, isChecked);
    };

    useEffect(() => {
        setFormData(initialFormData);
    }, [details]);

    const prevSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevSuccess && postSuccess) onPostSuccess();
    }, [postSuccess, prevSuccess]);

    const onSubmit = useCallback(() => {
        dispatch(updatePersonalDetails(formData));
    }, [formData]);

    return { formData, onChange, onSubmit, isPosting, error, handleSwitchClick };
};

export default useEditPersonalDetails;
