import { createAction } from '@reduxjs/toolkit';
import { api, handleErrors } from '../utils';

// Test 500 errors for debug
export const testErrorsRequest = createAction('TEST_ERRORS_REQUEST');
export const testErrorsFailure = createAction('TEST_ERRORS_FAILURE');

export const testErrors = () => async dispatch => {
  dispatch(testErrorsRequest())
    try {
        await api.get('healthcheck/health500');

    } catch (e) {
      const formattedErr = { ...e, message: 'This is a test Error', response: { status: 500 } };
      console.log(formattedErr);
        return handleErrors(dispatch, testErrorsFailure, formattedErr, true);
    }
};

// Test Native API crash
export const testAPIErrorsRequest = createAction('TEST_API_ERRORS_REQUEST');
export const testAPIErrorsFailure = createAction('TEST_API_ERRORS_FAILURE');

export const testAPINativeCrash = () => async dispatch => {
  dispatch(testAPIErrorsRequest())
    try {
        await api.get('healthcheck/unhandled-exception');

    } catch (e) {
      const formattedErr = { ...e, message: 'This is a native API test Error', response: { status: 500 } };
      console.log(formattedErr);
        return handleErrors(dispatch, testAPIErrorsFailure, formattedErr, true);
    }
};