import AUD_Flag from '../_content/images/flags/aud.jpg';
import INR_Flag from '../_content/images/flags/inr.jpg';
import CAD_Flag from '../_content/images/flags/cad.jpg';
import GBP_Flag from '../_content/images/flags/gbp.jpg';
import EUR_Flag from '../_content/images/flags/eur.jpg';
import USD_Flag from '../_content/images/flags/usd.jpg';

export const currencies = {
    GBP: '\u00A3',
    EUR: '\u20AC',
    USD: '\u0024',
}

export const allCurrencies = [
    {
        name: 'Australian Dollar',
        flag: AUD_Flag,
        currency_code: 'AUD',
        countries: ['Australia'],
    },
    {
        name: 'Indian Rupee',
        flag: INR_Flag,
        currency_code: 'INR',
        countries: ['India'],
    },
    {
        name: 'Canadian Dollar',
        flag: CAD_Flag,
        currency_code: 'CAD',
        countries: ['Canada'],
    },
    {
        name: 'British Pound',
        flag: GBP_Flag,
        currency_code: 'GBP',
        countries: ['United Kingdom', 'England', 'Scotland', 'Wales', 'Northern Ireland'],
    },
    {
        name: 'European Euro',
        flag: EUR_Flag,
        currency_code: 'EUR',
        countries: ['Andorra', 'Austria', 'Belgium', 'Cyprus', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Italy', 'Kosovo', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Monaco', 'Montenegro', 'Netherlands', 'Portugal', 'San Marino', 'Spain', 'Slovenia', 'Slovakia', 'Vatican City']
    },
    {
        name: 'U.S Dollar',
        flag: USD_Flag,
        currency_code: 'USD',
        countries: ['United States of America']
    },
];