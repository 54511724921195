import React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';
import { formatMoney } from 'lib/utils';

const DirectDebit = ({ data }) => {
    const history = useHistory();
    const translate = useTranslate('directDebits');

    return (
        <div className="direct-debit-container">
            <SectionTitle title={translate('active')} secondary />
            <div className="active-container">
                {data
                    .filter(({ status }) => status === 'ACTIVE')
                    .map(item => (
                        <div
                            key={item.id}
                            className="menu-item-container"
                            onClick={() =>
                                history.push(
                                    `/payments/direct-debit/${item.id}`,
                                )
                            }
                        >
                            <div className="menu-item-left">
                                <h2 className="menu-item-left-title">
                                    {item.payeeName}
                                </h2>
                                <h3 className="menu-item-left-date">
                                    {dayjs(item.paymentDueDate).format(
                                        'DD MMM YYYY',
                                    )}
                                </h3>
                            </div>
                            <div className="menu-item-right">
                                <h2 className="menu-item-right-amount">
                                    £{formatMoney(item.amount)}
                                </h2>
                                <div className="menu-item-arrow">
                                    <i className="fa fa-caret-right" />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            <SectionTitle title={translate('cancelled')} secondary />
            <div className="cancelled-container">
                {data
                    .filter(({ status }) => status === 'CANCELLED')
                    .map(item => (
                        <div
                            key={item.id}
                            className="menu-item-container"
                            onClick={() =>
                                history.push(
                                    `/payments/direct-debit/${item.id}`,
                                )
                            }
                        >
                            <div className="menu-item-left">
                                <h2 className="menu-item-left-title">
                                    {item.payeeName}
                                </h2>
                                <h3 className="menu-item-left-date">
                                    {dayjs(item.created).format('DD MMM YYYY')}
                                </h3>
                            </div>
                            <div className="menu-item-right">
                                <h2 className="menu-item-right-amount">
                                    £{item.amount}
                                </h2>
                                <div className="menu-item-arrow">
                                    <i className="fa fa-caret-right" />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DirectDebit;
