import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

// Fetch personal details
export const fetchPersonalDetailsRequest = createAction('FETCH_PERSONAL_DETAILS_REQUEST');
export const fetchPersonalDetailsSuccess = createAction('FETCH_PERSONAL_DETAILS_SUCCESS');
export const fetchPersonalDetailsFailure = createAction('FETCH_PERSONAL_DETAILS_FAILURE');

export const fetchPersonalDetails = () => async dispatch => {
    dispatch(fetchPersonalDetailsRequest());

    try {
        const { data } = await api.get('account/personal');
        return dispatch(fetchPersonalDetailsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchPersonalDetailsFailure, e, true);
    }
};

// Update personal details
export const updatePersonalDetailsRequest = createAction('UPDATE_PERSONAL_DETAILS_REQUEST');
export const updatePersonalDetailsSuccess = createAction('UPDATE_PERSONAL_DETAILS_SUCCESS');
export const updatePersonalDetailsFailure = createAction('UPDATE_PERSONAL_DETAILS_FAILURE');

export const updatePersonalDetails = postBody => async dispatch => {
    dispatch(updatePersonalDetailsRequest());

    try {
        const { data } = await api.put('account/personal', postBody);
        return dispatch(updatePersonalDetailsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, updatePersonalDetailsFailure, e, true);
    }
};
