import { createReducer } from '@reduxjs/toolkit';

import {
    fetchAccountRequest,
    fetchAccountSuccess,
    fetchAccountFailure,
    createCardHolderRequest,
    createCardHolderSuccess,
    createCardHolderFailure,
    regradeAccountRequest,
    regradeAccountSuccess,
    regradeAccountFailure,
    putUpdatePasswordRequest,
    putUpdatePasswordSuccess,
    putUpdatePasswordFailure,
    fetchAccountsListRequest,
    fetchAccountsListSuccess,
    fetchAccountsListFailure,
    updateSelectedAccountRequest,
    updateSelectedAccountSuccess,
    updateSelectedAccountFailure,
    showAccountBlockedModal,
    hideAccountBlockedModal
} from '../actions';

const initialState = {
    isFetching: false,
    isFetchingAccounts: false,
    isPosting: false,
    error: null,
    account: {},
    postSuccess: false,
    postError: null,
    accountsList: [],
    showAccountBlockedModal: false,
};

export default createReducer(initialState, {
    [fetchAccountRequest]: handleFetchRequest,
    [fetchAccountSuccess]: handleFetchSuccess,
    [fetchAccountFailure]: handleFailure,
    [createCardHolderRequest]: handlePostRequest,
    [createCardHolderSuccess]: handlePostSuccess,
    [createCardHolderFailure]: handlePostFailure,
    [regradeAccountRequest]: handlePostRequest,
    [regradeAccountSuccess]: handlePostSuccess,
    [regradeAccountFailure]: handlePostFailure,
    [putUpdatePasswordRequest]: handlePostRequest,
    [putUpdatePasswordSuccess]: handlePostSuccess,
    [putUpdatePasswordFailure]: handlePostFailure,
    [fetchAccountsListRequest]: handleFetchAccountsRequest,
    [fetchAccountsListSuccess]: handleFetchAccountsSuccess,
    [fetchAccountsListFailure]: handleFailure,
    [updateSelectedAccountRequest]: handlePostRequest,
    [updateSelectedAccountSuccess]: handleUpdateSelectedAccountSuccess,
    [updateSelectedAccountFailure]: handlePostFailure,
    [showAccountBlockedModal]: handleShowAccountBlockedModal,
    [hideAccountBlockedModal]: handleHideAccountBlockedModal,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
    state.postError = false
}

function handleFetchAccountsRequest(state) {
    state.isFetchingAccounts = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.account = action.payload.account;
}

function handleFetchAccountsSuccess(state, action) {
    state.isFetchingAccounts = false;
    state.accountsList = action.payload;
}

function handleUpdateSelectedAccountSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
    state.account = {};
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.error = null;
    state.postSuccess = false;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleFailure(state, action) {
    state.isFetching = false;
    state.isFetchingAccounts = false;
    state.error = action.payload;
}

function handleShowAccountBlockedModal(state) {
    state.showAccountBlockedModal = true;
}

function handleHideAccountBlockedModal(state) {
    state.showAccountBlockedModal = false;
}
