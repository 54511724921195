import { useDispatch, useSelector } from 'react-redux';
import { regradeAccount } from '../actions';
import { accountRolesLookup } from '../constants';
import { subscriptionFees } from '../constants/fees';
import { formatMoney } from '../utils';

import {
    getAccountIsPosting,
    getAccountPostSuccess,
    getAccountPostError,
} from '../selectors';

export default function useRegradeSubscription(currentRole, newRole) {
    const dispatch = useDispatch();

    const isPosting = useSelector(getAccountIsPosting);
    const postSuccess = useSelector(getAccountPostSuccess);
    const error = useSelector(getAccountPostError);

    const currentCost = subscriptionFees[currentRole];
    const newCost = subscriptionFees[newRole];

    const currentSubscription = accountRolesLookup[currentRole].toLowerCase();
    const newSubscription = accountRolesLookup[newRole].toLowerCase();

    const regradeType = newCost > currentCost ? 'upgrading' : 'downgrading';
    const differenceType = newCost > currentCost ? 'increase' : 'decrease';
    const isUpgrade = newCost > currentCost;
    const isDowngradeToEssential = +newCost === 0;

    const description = ` You are ${regradeType} from ${currentSubscription} to
    ${newSubscription}. This will ${differenceType} your
    monthly fee from £${formatMoney(currentCost)} to £${formatMoney(newCost)}.`;

    function handleSubmit() {
        dispatch(regradeAccount({ productClassCode: newRole }));
    }

    return {
        isPosting,
        postSuccess,
        error,
        description,
        handleSubmit,
        isUpgrade,
        isDowngradeToEssential,
        currentCost,
        newCost,
    };
}
