import React from 'react';

import { TRANSACTIONS } from 'constants/shared/routes';
import useTranslate from 'lib/languages/useTranslate';
import SectionTitle from '../../sectionTitle/presentational/SectionTitle';

const Error = ({
    message = 'An error occurred',
    className = '',
    backRoute = TRANSACTIONS,
}) => {
    const translate = useTranslate('generic');
    return (
        <div className={`error-message ${className}`}>
            <SectionTitle title={translate('error')} backRoute={backRoute} />
            <p>{message}</p>
        </div>
    );
};

export default Error;
