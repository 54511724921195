import React from 'react';
import { useDispatch } from 'react-redux';
import { issueCard } from 'lib/actions';

import LSDStatusCardModal from '../presentational/LSDStatusCardModal';

const LSDStatusCardModalContainer = ({ hideModal, cardSerial }) => {
    const dispatch = useDispatch();

    const handleClose = () => hideModal();

    const handleSubmit = () => {
        dispatch(issueCard(cardSerial));
        handleClose();
    };

    return (
        <div className="status-card-modal">
            <LSDStatusCardModal
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />
        </div>
    );
};

export default LSDStatusCardModalContainer;
