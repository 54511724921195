import { createReducer } from '@reduxjs/toolkit';
import { setLanguageCode } from '../actions/languages';
import { languageCodes } from '../languages/constants';

const initialState = {
    languageCode: languageCodes.ENGLISH,
};

export default createReducer(initialState, {
    [setLanguageCode]: handleSetLanguage,
});

function handleSetLanguage(state, action) {
    state.languageCode = action.payload;
}
