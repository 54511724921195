import { createReducer, createAction } from '@reduxjs/toolkit';
import {
    addressLookupSearchRequest,
    addressLookupSearchSuccess,
    addressLookupSearchFailure,
    addressLookupSingleRequest,
    addressLookupSingleSuccess,
    addressLookupSingleFailure,
} from '../actions';

const initialState = {
    isFetching: false,
    isPosting: false,
    error: null,
    postSuccess: false,
    postError: null,
    results: [],
};

export const addressLookupResultsReset = createAction('addressLookupResultsReset');

export default createReducer(initialState, {
    [addressLookupSearchRequest]: handleFetchRequest,
    [addressLookupSearchSuccess]: handleAddressLookupSearchSuccess,
    [addressLookupSearchFailure]: handleFailure,
    [addressLookupSingleRequest]: handleFetchRequest,
    [addressLookupSingleSuccess]: handleAddressLookupSingleSuccess,
    [addressLookupSingleFailure]: handleFailure,
    [addressLookupResultsReset]: resetAddressLookupResults,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
    state.postError = false;
}

function handleAddressLookupSearchSuccess(state, action) {
    state.isFetching = false;
    state.error = null;
    state.results = action.payload;
}

function handleAddressLookupSingleSuccess(state, action) {
    state.isFetching = false;
    state.error = null;
    state.results = action.payload;
}

function handleFailure(state, action) {
    state.isFetching = false;
    state.isFetchingAccounts = false;
    state.error = action.payload;
}

export function resetAddressLookupResults(state) {
    state.results = [];
}
