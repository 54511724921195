import { createReducer } from '@reduxjs/toolkit';
import { twCreateTransactionSetPostBody } from '../actions';
import {
    twCreateTransactionHandleStaticFormChange,
    twCreateTransactionHandleDynamicFormChange,
    twCreateTransactionRemoveDynamicField,
    twCreateTransactionResetForm,
} from '../actions';

const initialStaticData = {};

const initialState = {
    formStaticData: initialStaticData,
    formDynamicData: {},
    postBody: {},
};

export default createReducer(initialState, {
    [twCreateTransactionHandleStaticFormChange]: handleStaticFormChange,
    [twCreateTransactionHandleDynamicFormChange]: handleDynamicFormChange,
    [twCreateTransactionRemoveDynamicField]: handleRemoveDynamicField,
    [twCreateTransactionResetForm]: handleResetForm,
    [twCreateTransactionSetPostBody]: handleSetPostBody,
});

function handleSetPostBody(state, action) {
    state.postBody = action.payload;
}
function handleStaticFormChange(state, action) {
    state.formStaticData[action.payload.name] = action.payload.value;
}

function handleDynamicFormChange(state, action) {
    state.formDynamicData[action.payload.name] = action.payload.value;
}

function handleRemoveDynamicField(state, action) {
    delete state.formDynamicData[action.payload];
}

function handleResetForm(state) {
    state.formStaticData = initialStaticData;
    state.formDynamicData = {};
}
