import React from 'react';

import Switch from 'components/shared/generic/switch/presentational/Switch';
import Error from 'components/shared/generic/errorMessage/presentational/Error';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';

const CommunicationPreferences = ({
    emailNotification,
    pushNotification,
    smsNotification,
    handleClick,
    postError,
}) => {
    const translate = useTranslate('commsPreferences');
    return (
        <div className="switch-list-container">
            <div className="switch">
                <SectionTitle
                    title={translate('emailNotifications')}
                    secondary
                />
                <Switch
                    name="emailNotification"
                    value={emailNotification}
                    onChange={handleClick}
                    tracking
                    trackingLabel="RECEIVE_EMAILS_SWITCH_CLICK"
                />
            </div>
            <div className="switch">
                <SectionTitle
                    title={translate('pushNotifications')}
                    secondary
                />
                <Switch
                    name="pushNotification"
                    value={pushNotification}
                    onChange={handleClick}
                    tracking
                    trackingLabel="RECEIVE_PUSH_NOTIFICATIONS_SWITCH_CLICK"
                />
            </div>
            <div className="switch">
                <SectionTitle title={translate('smsNotifications')} secondary />

                <Switch
                    name="smsNotification"
                    value={smsNotification}
                    onChange={handleClick}
                    tracking
                    trackingLabel="RECEIVE_SMS_SWITCH_CLICK"
                />
            </div>
            {!!postError && <Error message={translate('errorMessage')} />}
        </div>
    );
};

export default CommunicationPreferences;
