export const getPayeesIsFetching = state => state.payeesReducer.isFetching;
export const getPayeesError = state => state.payeesReducer.error;
export const getPayeesPostError = state => state.payeesReducer.postError;
export const getPayees = state => state.payeesReducer.payees;
export const getPayeesPostSuccess = state => state.payeesReducer.postSuccess;
export const getPayeesIsPosting = state => state.payeesReducer.isPosting;
export const getSelectedPayeeId = state => state.payeesReducer.selectedPayeeId;
export const getSelectedPayee = state =>
    state.payeesReducer.payees[state.payeesReducer.selectedPayeeId];
export const getLastPayeePage = state => state.payeesReducer.last;
