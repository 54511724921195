import React from 'react';
import FeesModal from '../feesModal/FeesModal';

function PaymentFeeModal({ fee, additionalCost, handleSubmit, closeModal }) {
    return (
        <FeesModal
            action="Making a payment"
            fee={fee}
            additionalCost={additionalCost}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
        />
    );
}

export default PaymentFeeModal;
