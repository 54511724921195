import React from 'react';
import { useCheckAccountStatus } from 'lib/hooks';

import MakePaymentIcon from '_content/images/icons/payment/manage-payee.png';
import MakePaymentIconDark from '_content/images/icons/payment/manage-payee-dark.png';
import StandingOrderIcon from '_content/images/icons/payment/standing-order.png';
import StandingOrderIconDark from '_content/images/icons/payment/standing-order-dark.png';
import DirectDebitIcon from '_content/images/icons/payment/direct-debit.png';
import DirectDebitIconDark from '_content/images/icons/payment/direct-debit-dark.png';
import ManagePayeeIcon from '_content/images/icons/more/refer-friend.png';
import ManagePayeeIconDark from '_content/images/icons/more/refer-friend-dark.png';
import AddCashIcon from '_content/images/icons/more/fees.png';
import AddCashIconDark from '_content/images/icons/more/fees-dark.png';
import SalaryIcon from '_content/images/icons/more/account-details.png';
import SalaryIconDark from '_content/images/icons/more/account-details-dark.png';

import MenuItem from 'components/shared/generic/menuItem/presentational/MenuItem';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';

const Payments = ({
    onMakePaymentClick,
    onStandingOrdersClick,
    onDirectDebitClick,
    onManagePayeeClick,
    onAddCashClick,
    onSalaryClick,
    isDarkModeEnabled,
}) => {
    const translate = useTranslate('paymentsList');
    const [isDormant] = useCheckAccountStatus();

    return (
        <div className="payments-container">
            {isDormant ? (
                <SectionTitle title={translate('dormentInfo')} />
            ) : (
                <>
                    <SectionTitle title={translate('sendPayments')} />
                    <div className="payments-menu-container">
                        <SectionTitle
                            title={translate('sendMoney')}
                            secondary
                        />
                        <div className="menu-container">
                            <MenuItem
                                title={translate('makeAPayment')}
                                icon={
                                    <img
                                        src={
                                            isDarkModeEnabled
                                                ? MakePaymentIconDark
                                                : MakePaymentIcon
                                        }
                                        alt="Make a payment"
                                    />
                                }
                                onClick={onMakePaymentClick}
                            />
                            <MenuItem
                                title={translate('managePayees')}
                                icon={
                                    <img
                                        src={
                                            isDarkModeEnabled
                                                ? ManagePayeeIconDark
                                                : ManagePayeeIcon
                                        }
                                        alt="Manage Payees"
                                    />
                                }
                                onClick={onManagePayeeClick}
                            />
                            <MenuItem
                                title={translate('viewStandingOrders')}
                                icon={
                                    <img
                                        src={
                                            isDarkModeEnabled
                                                ? StandingOrderIconDark
                                                : StandingOrderIcon
                                        }
                                        alt="View Standing Orders"
                                    />
                                }
                                onClick={onStandingOrdersClick}
                            />
                            <MenuItem
                                title={translate('viewDirectDebits')}
                                icon={
                                    <img
                                        src={
                                            isDarkModeEnabled
                                                ? DirectDebitIconDark
                                                : DirectDebitIcon
                                        }
                                        alt="View Direct Debits"
                                    />
                                }
                                onClick={onDirectDebitClick}
                            />
                        </div>
                    </div>

                    <div className="payments-menu-container">
                        <SectionTitle title={translate('addMoney')} secondary />

                        <MenuItem
                            title={translate('addCash')}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? AddCashIconDark
                                            : AddCashIcon
                                    }
                                    alt="Add Cash"
                                />
                            }
                            onClick={onAddCashClick}
                        />

                        <MenuItem
                            title={translate('salary')}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? SalaryIconDark
                                            : SalaryIcon
                                    }
                                    alt="Salary"
                                />
                            }
                            onClick={onSalaryClick}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Payments;
