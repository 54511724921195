import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../actions';
import { getAccountError, getAccountIsPosting, getAccountPostSuccess } from '../selectors/account';

import { useForm, usePrevious } from '../utils';

const useUpdatePassword = onPostSuccess => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getAccountIsPosting);
    const postSuccess = useSelector(getAccountPostSuccess);
    const error = useSelector(getAccountError);

    const [formData, handleChange] = useForm({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const handleSubmit = () => {
        dispatch(updatePassword(formData));
    };

    const prevSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevSuccess && postSuccess) onPostSuccess();
    }, [prevSuccess, postSuccess]);

    return { formData, handleChange, handleSubmit, isPosting, error, postSuccess };
};

export default useUpdatePassword;
