import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginSteps } from 'lib/constants';
import {
    getAuthError,
    getAuthIsPosting,
    getAuthLoginStep,
    getPostTokenSuccess,
} from 'lib/selectors';
import { updateLanguageCode } from 'lib/actions';
import useLocalStorage from './useLocalStorage';
import { isEmpty } from 'lib/utils';

export default () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const loginStep = useSelector(getAuthLoginStep);
    const isPosting = useSelector(getAuthIsPosting);
    const error = useSelector(getAuthError);
    const token = useSelector(getPostTokenSuccess);

    const [languageCode] = useLocalStorage('languageCode', {});

    useEffect(() => {
        if (loginStep === loginSteps.Complete) {
            localStorage.setItem('token', token);
            if (!isEmpty(languageCode))
                dispatch(updateLanguageCode(languageCode));
            history.push('/');
        }
    }, [loginStep]);

    return { loginStep, isPosting, error };
};
