import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createStandingOrder } from '../actions';
import { useForm, usePrevious } from '../utils';
import {
    getStandingOrderIsPosting,
    getStandingOrderPostSuccess,
    getStandingOrderPostError,
    getSelectedPayeeId,
} from '../selectors';

export default (onSubmit, onError) => {
    const dispatch = useDispatch();
    const success = useSelector(getStandingOrderPostSuccess);
    const isPosting = useSelector(getStandingOrderIsPosting);
    const error = useSelector(getStandingOrderPostError);
    const selectedId = useSelector(getSelectedPayeeId);

    const [form, handleChange] = useForm({
        amount: 0,
        startDate: null,
        endDate: null,
        recurType: '3',
        reference: '',
        password: '',
    });

    const prevProps = usePrevious({ success, isPosting });

    useEffect(() => {
        if (!prevProps.success && success && onSubmit) {
            onSubmit();
        }

        if (prevProps.isPosting && error && onError) {
            onError();
        }
        //eslint-disable-next-line
    }, [isPosting]);

    const handleSubmit = () => {
        const postBody = {
            ...form,
            amount: form.amount.toString().includes('£')
                ? parseInt((form.amount.substring(1) * 100).toFixed(0))
                : parseInt((Number(form.amount) * 100).toFixed(0)),
            payeeID: selectedId,
            startOn: form.startOn ? dayjs(form.startOn).format('YYYY-MM-DD') : null,
            endOn: form.endOn ? dayjs(form.endOn).format('YYYY-MM-DD') : null,
            recurType: Number(form.recurType),
        };
        dispatch(createStandingOrder(postBody));
    };

    return {
        form,
        isPosting,
        handleChange,
        handleSubmit,
        error,
    };
};
