export const TW_ACCOUNT_STATES = {
    UNKNOWN: 'UNKNOWN',
    DOES_NOT_EXIST: 'DOES_NOT_EXIST',
    READY: 'READY',
    CONFLICT: 'CONFLICT',
    ERROR: 'ERROR',
    CREATING: 'CREATING',
    LINKING_EXISTING: 'LINKING_EXISTING',
};

export const TW_TRANSACTIONS_STATUS_VALUES = {
    PENDING: 'incoming_payment_waiting',
    WAITING_ON_RECIPIENT: 'waiting_recipient_input_to_proceed',
    PROCESSING: 'processing',
    FUNDS_CONVERTED: 'funds_converted',
    COMPLETED: 'outgoing_payment_sent',
    CANCELLED: 'cancelled',
    REFUNDED: 'funds_refunded',
    BOUNDED_BACK: 'bounced_back',
    CHARGED_BACK: 'charged_back',
    UNKNOWN: 'unknown',
};

export const TW_TRANSACTIONS_STATUS_NAMES = {
    incoming_payment_waiting: 'Pending',
    waiting_recipient_input_to_proceed: 'Waiting on recipient input',
    processing: 'Processing',
    funds_converted: 'Funds converted',
    outgoing_payment_sent: 'Completed',
    cancelled: 'Cancelled',
    funds_refunded: 'Refunded',
    bounced_back: 'Bounced back',
    charged_back: 'Charged back',
    unknown: 'Unknown',
};