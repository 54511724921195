import React from 'react';

import Select from 'components/shared/form/presentational/Select';
import Button from 'components/shared/generic/button/presentational/Button';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import useAccountSelect from './hooks/useAccountSelect';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Form from 'components/shared/form/presentational/Form';
import Field from 'components/shared/form/presentational/Field';

const AccountSelectModal = ({ handleModalClose, accounts, account }) => {
    const {
        form,
        handleChange,
        formattedAccountOptions,
        handleSubmit,
        isPosting,
    } = useAccountSelect(accounts, account);

    return (
        <ModalOuterContainer customButtons>
            <div className="description">
                <Typography tag="h2">Select account</Typography>
            </div>
            <Form omitButtons onSubmit={handleSubmit} isPosting={isPosting}>
                <Field>
                    <Select
                        name="ppsAccountNo"
                        onChange={handleChange}
                        value={form.ppsAccountNo}
                        options={formattedAccountOptions}
                        omitPlaceholder
                        classes="generic-input modal-select"
                        required
                    />
                </Field>
                <ButtonContainer>
                    <Button
                        className="cancel"
                        onClick={e => handleModalClose(e)}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" isPosting={isPosting}>
                        Choose account
                    </Button>
                </ButtonContainer>
            </Form>
        </ModalOuterContainer>
    );
};

export default AccountSelectModal;
