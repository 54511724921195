import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountRoles, cardSatusValues } from 'lib/constants';
import { dispatchHideModal, dispatchShowModal } from 'utils/modals';
import {
    STATUS_CARD_MODAL,
    REPLACEMENT_CARD_FEE,
    LSD_STATUS_CARD_MODAL,
} from 'constants/shared/modalTypes';
import NormalCard from '../../cardTypes/NormalCard';
import FrozenCard from '../../cardTypes/FrozenCard';
import PlaceholderCard from '../../cardTypes/PlaceholderCard';
import IssuedCard from '../../cardTypes/IssuedCard';
import NewCard from '../../cardTypes/NewCard';
import EndedCard from '../../cardTypes/EndedCard';
import { getAllFees, getCardsIsFetching } from 'lib/selectors';

const { BLOCKED, ISSUED, LOST, STOLEN, DAMAGED } = cardSatusValues;

const Card = ({ card, account, position, onAddCardHolderClick }) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getCardsIsFetching);
    const allFees = useSelector(getAllFees);

    const handleReportStatus = () => {
        dispatchShowModal(dispatch, REPLACEMENT_CARD_FEE, {
            fee: allFees.replacementCard,
            handleSubmit: handleShowReplacementCardModal,
            closeModal: () => dispatchHideModal(dispatch),
        });
    };

    const handleReportLSDStatus = () => {
        dispatchShowModal(dispatch, REPLACEMENT_CARD_FEE, {
            fee: allFees.replacementCard,
            handleSubmit: handleShowLSDReplacementCardModal,
            closeModal: () => dispatchHideModal(dispatch),
        });
    };

    const handleShowReplacementCardModal = () => {
        dispatchShowModal(dispatch, STATUS_CARD_MODAL, {
            cardSerial: card.cardSerial,
            account: account,
        });
    };

    const handleShowLSDReplacementCardModal = () => {
        dispatchShowModal(dispatch, LSD_STATUS_CARD_MODAL, {
            cardSerial: card.cardSerial,
        });
    };

    const isDarkText = account.productType === accountRoles.Essential;

    if (!card) {
        return <PlaceholderCard />;
    }

    const { status } = card;

    if (status == BLOCKED)
        return (
            <FrozenCard
                info={{ ...card, ...account }}
                position={position}
                onClick={handleReportStatus}
                isDarkText={isDarkText}
            />
        );

    if (status === ISSUED)
        return (
            <IssuedCard
                position={position}
                card={card}
                isDarkText={isDarkText}
            />
        );

    if (status === 'N' && !isFetching)
        return (
            <NewCard
                onAddCardHolderClick={onAddCardHolderClick}
                isDarkText={isDarkText}
            />
        );

    if ([LOST, STOLEN, DAMAGED].includes(status))
        return (
            <EndedCard
                position={position}
                card={card}
                onClick={handleReportLSDStatus}
                isDarkText={isDarkText}
            />
        );

    return (
        <NormalCard
            info={{ ...card, ...account }}
            position={position}
            isDarkText={isDarkText}
        />
    );
};

export default Card;
