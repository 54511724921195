import { createReducer } from '@reduxjs/toolkit';

import {
    fetchCommunicationPreferencesRequest,
    fetchCommunicationPreferencesSuccess,
    fetchCommunicationPreferencesFailure,
    updateCommunicationPreferencesRequest,
    updateCommunicationPreferencesSuccess,
    updateCommunicationPreferencesFailure,
} from '../actions';

const initialState = {
    isFetching: false,
    error: null,
    commsPreferences: {},
    isPosting: false,
    postError: null,
};

export default createReducer(initialState, {
    [fetchCommunicationPreferencesRequest]: handleFetchRequest,
    [fetchCommunicationPreferencesSuccess]: handleFetchSuccess,
    [fetchCommunicationPreferencesFailure]: handleFetchFailure,
    [updateCommunicationPreferencesRequest]: handlePostRequest,
    [updateCommunicationPreferencesSuccess]: handlePostSuccess,
    [updateCommunicationPreferencesFailure]: handlePostFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.commsPreferences = action.payload;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostSuccess(state) {
    state.isPosting = false;
}
