import React, { useLayoutEffect } from 'react';
import GooglePlacesAutocomplete from 'react-places-autocomplete';
import withFieldValidation from '../hocs/withFieldValidation';
import { validatePlaceTypes } from 'utils/autocomplete';

const PostCodeAutoCompleteInput = ({ classes, onChange, disabled }) => {
    useLayoutEffect(() => {
        if (window.pca) window.pca.load();
    }, [window.pca]);

    const updateValues = e => {
        e.preventDefault();
        const fields = [
            'buildingNo',
            'buildingName',
            'streetAddress',
            'town',
            'county',
            'postcode',
            'country',
        ]
        
        fields.forEach(name => {
            document.getElementsByName(name).forEach(({ value }) => {
                onChange(name, value);
            });
        });
    };

    return (
        <div className={`sm-input postcode ${disabled && 'disabled'}`}>
            <LoqateForm
                updateValue={updateValues}
                disabled={disabled}
                classes={classes}
            />
        </div>
    );
};

const LoqateForm = ({ disabled, classes, updateValue }) => (
    <div>
        <div className="input-container">
            <i className="auto-complete-icon sm-input-prepend-icon fas fa-search fa-2x"></i>
            <input
                name="addressSearch"
                placeholder="Search postcode or address"
                className={`${classes} location-search-input`}
                disabled={disabled}
                onBlur={updateValue}
            />
        </div>
    </div>
);

// eslint-disable-next-line no-unused-vars
const GooglePlacesForm = ({
    autoCompleteVal,
    setAutoCompleteVal,
    onPostCodeSelect,
    disabled,
    classes,
}) => {
    return (
        <GooglePlacesAutocomplete
            value={autoCompleteVal}
            onChange={val => setAutoCompleteVal(val)}
            onSelect={onPostCodeSelect}
            searchOptions={{
                componentRestrictions: {
                    country: ['uk'],
                },
            }}
            disabled={disabled}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                    <div className="input-container">
                        <i className="auto-complete-icon sm-input-prepend-icon fas fa-search fa-2x"></i>
                        <input
                            {...getInputProps({
                                placeholder: 'Search postcode or address',
                                className: `${classes} location-search-input`,
                            })}
                            disabled={disabled}
                        />
                    </div>

                    <div className="autocomplete-dropdown-container">
                        {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            const style = suggestion.active
                                ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                      borderBottom: '1px solid #ccc',
                                  }
                                : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                      borderBottom: '1px solid #ccc',
                                  };

                            if (!validatePlaceTypes(suggestion.types))
                                return null; // filter out invalid results

                            return (
                                <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </GooglePlacesAutocomplete>
    );
};

export default withFieldValidation(PostCodeAutoCompleteInput);
