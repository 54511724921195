import Typography from 'components/shared/generic/typography/presentational/Typography';
import React, { useState, useRef, useEffect } from 'react';

import {
    languageCodeOptions,
    languageCodesWords,
} from 'lib/languages/constants';
import languageFlags from 'lib/_content/images/languageFlags';

import withFieldValidation from '../hocs/withFieldValidation';

const LanguageSelect = ({
    name,
    disabled = false,
    value = null,
    onChange,
    showError,
    iconClass = '',
    classes = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const node = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isOpen && !hasOpened) setHasOpened(true);
        else if (!isOpen && hasOpened) showError();
        // eslint-disable-next-line
    }, [isOpen]);

    if (languageCodeOptions.length <= 1) return null;

    return (
        <div
            className={`language-switcher ${isOpen ? 'is-open' : ''}`}
            ref={node}
            onClick={() => !disabled && setIsOpen(!isOpen)}
        >
            <span className="language-flag-container">
                <img
                    src={value ? languageFlags[value] : languageFlags['eng']}
                    alt={`${value} flag`}
                    className="language-flag"
                />
                <Typography className="text">
                    {languageCodesWords[value]}
                </Typography>
                <div className="selected-box">
                    <i
                        className={`arrow ${
                            iconClass.length ? iconClass : 'fal fa-angle-down'
                        }`}
                    />
                </div>
            </span>
            <div
                className={`language-select select-list size-lg-12 ${
                    disabled ? 'disabled' : ''
                } ${classes}`}
            >
                {isOpen && (
                    <div className="option-selection">
                        <div className="option-container">
                            <div
                                className={`option ${!value ? 'active' : ''}`}
                                onClick={e => handleSelect(e, null)}
                            />

                            {languageCodeOptions.map((opt, i) => (
                                <>
                                    <div
                                        key={`${opt.value} - ${i}`}
                                        className={`option ${
                                            value === opt.value ? 'active' : ''
                                        } ${
                                            !value && opt.value === 'eng'
                                                ? 'active'
                                                : ''
                                        }`}
                                        onClick={e =>
                                            handleSelect(e, opt.value)
                                        }
                                    >
                                        <img
                                            src={languageFlags[opt.value]}
                                            alt=""
                                        />
                                        <p className="label">{opt.label}</p>
                                        {/* {!!opt.subLabel && (
                                            <p className="sub-label">
                                                ({opt.subLabel})
                                            </p>
                                        )} */}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    function handleClick(e) {
        // inside click
        if (node.current?.contains(e.target)) {
            return;
        }

        // outside click
        setIsOpen(false);
    }

    function handleSelect(e, clicked) {
        e.preventDefault();

        if (value === clicked) return;
        onChange(name, clicked);
    }
};

export default withFieldValidation(LanguageSelect);
