import React from 'react';
import { cardImages, cardPos } from 'constants/shared/enums';
import Card from '../presentational/Card';
import { accountRoles } from 'lib/constants';

import PlaceholderVerticalCard from '_content/images/cards/SM_Placeholder.png';

const CarouselContainer = ({
    cards,
    account,
    onAddCardHolderClick,
    handleCardClick,
}) => {
    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {cards.map((card, index) => {
                    const position = cardPos[index];
                    return (
                        <div
                            key={index}
                            className={`carousel-item ${position}`}
                            onClick={() => handleCardClick(position, index)}
                        >
                            <div
                                className={`card-content ${
                                    account.productType === accountRoles.Premuim
                                        ? 'primary'
                                        : ''
                                }`}
                            >
                                {!!card && (
                                    <img
                                        src={
                                            !card.cardSerial
                                                ? PlaceholderVerticalCard
                                                : cardImages[
                                                      account.productType
                                                  ]
                                        }
                                        alt="suits-me card"
                                    />
                                )}
                                <Card
                                    card={card}
                                    account={account}
                                    position={position}
                                    onAddCardHolderClick={onAddCardHolderClick}
                                ></Card>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CarouselContainer;
