export const getCards = state =>
    Object.values(state.cardsReducer.cards).sort((a, b) => {
        if (a.additionalCard && !b.additionalCard) return 1;
        if (!a.additionalCard && b.additionalCard) return -1;

        if (new Date(a.expiryDate) > new Date(b.expiryDate)) return 1;
        if (new Date(a.expiryDate) < new Date(b.expiryDate)) return -1;

        return 0;
    });

export const getCardsIsFetching = state => state.cardsReducer.isFetching;
export const getCardsPin = state => state.cardsReducer.pin;
export const getCardsIsFetchingPin = state => state.cardsReducer.isFetchingPin;
export const getCardsPinError = state => state.cardsReducer.pinError;
export const getCardsIsPosting = state => state.cardsReducer.isPosting;
export const getCardsPostSuccess = state => state.cardsReducer.postSuccess;
export const getCardsError = state => state.cardsReducer.error;
