module.exports = {
    API_URL: 'https://euro-api.suitsme.madeatsilverchip.com/api',
    RAW_S3_STORAGE_URL: '',
    TW_CLIENT_ID: 'suitsme',
    TW_REDIRECT_URI: 'https://suitsme.madeatsilverchip.com/international/check',
    TW_AUTH_LINK_URL: 'https://sandbox.transferwise.tech/oauth/authorize',
    CAPTCHA_SITE_KEY: '6Lcmub8bAAAAAPFEFSXyv1gDgfXUixXl5k1LlNyQ',
    CAPTCHA_SECRET_KEY: '6Lcmub8bAAAAABp5uA062f5k71UCeM7lweyp50ts',
    USERNAME_REMINDER_LINK: 'https://reset.smchost.co.uk/username-reminder',
};
