import { useDispatch, useSelector } from 'react-redux';
import {
    setLanguageCode,
    updateLanguageCode,
} from 'lib/actions/languages';
import { getLanguage } from 'lib/selectors';
import useLocalStorage from 'utils/hooks/useLocalStorage';

export default function useUpdateLanguage() {
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const [, setLangInStorage] = useLocalStorage('languageCode', null);

    const handleChange = (_, value) => {
        setLangInStorage(value);
        dispatch(updateLanguageCode(value));
        dispatch(setLanguageCode(value));
        dispatch(updateLanguageCode(value));
    };

    return [language, handleChange];
}
