import React from 'react';
import {
    SUITS_ME_ABOUT_US,
    SUITS_ME_ACCESSIBILITY,
    SUITS_ME_ACTIVATE_DEBIT_CARD,
    SUITS_ME_BLOCKED_ACCOUNT,
    SUITS_ME_CONTACT_US,
    SUITS_ME_FINANCIAL_ABUSE,
    SUITS_ME_MANAGING_GUIDE,
    SUITS_ME_PRIVACY_POLICY,
    SUITS_ME_RESET_PASSWORD,
    SUITS_ME_SECURITY_POLICY,
    SUITS_ME_TERMS_AND_CONDITIONS,
    SUITS_ME_VIEW_GUIDE,
} from 'constants/shared/routes';

import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import GridContainer from 'components/shared/generic/gridContainer/presentational/GridContainer';
import MenuItem from 'components/shared/generic/menuItem/presentational/MenuItem';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import analytics from 'utils/analytics';
import useTranslate from 'lib/languages/useTranslate';
import AppStoreBadges from './AppStoreBadges';

const LoginTemplate = ({ children }) => {
    const translate = useTranslate('loginWeb');

    return (
        <div className="sm-login-container">
            <h1 className="title">{translate('titleText')}</h1>
            <PageContainer>
                <GridContainer fullWidth col="2" gap={96}>
                    <div>{children}</div>
                    <div className="help-and-support">
                        <h2 className="secondary-title">
                            {translate('helpAndSupport')}
                        </h2>
                        <MenuItem
                            small
                            title={translate(
                                'supportNav.setupYourOnlineBanking',
                            )}
                            href={SUITS_ME_VIEW_GUIDE}
                            target="blank"
                            icon={
                                <span className="login-info-circle">
                                    <i className="fa fa-info" />
                                </span>
                            }
                            tracking
                        ></MenuItem>
                        <MenuItem
                            small
                            title={translate('supportNav.activateDebitCard')}
                            href={SUITS_ME_ACTIVATE_DEBIT_CARD}
                            target="blank"
                            icon={
                                <span className="login-info-circle">
                                    <i className="fa fa-info" />
                                </span>
                            }
                            tracking
                        ></MenuItem>
                        <MenuItem
                            small
                            title={translate('supportNav.resetPassword')}
                            href={SUITS_ME_RESET_PASSWORD}
                            target="blank"
                            icon={
                                <span className="login-info-circle">
                                    <i className="fa fa-info" />
                                </span>
                            }
                            tracking
                        ></MenuItem>
                        <MenuItem
                            small
                            title={translate('supportNav.manageOnlineBanking')}
                            href={SUITS_ME_MANAGING_GUIDE}
                            target="blank"
                            icon={
                                <span className="login-info-circle">
                                    <i className="fa fa-info" />
                                </span>
                            }
                            tracking
                        ></MenuItem>
                        <MenuItem
                            small
                            title={translate('supportNav.contactUs')}
                            href={SUITS_ME_CONTACT_US}
                            target="blank"
                            icon={
                                <span className="login-info-circle">
                                    <i className="fa fa-info" />
                                </span>
                            }
                            tracking
                        ></MenuItem>
                    </div>
                </GridContainer>
            </PageContainer>
            <div className="help-statement">
                <PageContainer>
                    <h2 className="secondary-title">
                        {translate('helpStatement.titleText')}
                    </h2>
                    <Typography>
                        {translate('helpStatement.descriptionText')}
                        <a
                            target="blank"
                            onClick={() =>
                                analytics.sendCTAEvent(
                                    'Button Click',
                                    'Financial Abuse Support Guide',
                                )
                            }
                            href={SUITS_ME_FINANCIAL_ABUSE}
                        >
                            {' '}
                            {translate('helpStatement.linkText')}
                        </a>
                    </Typography>
                </PageContainer>
            </div>
            <div className="login-footer">
                <PageContainer>
                    <div className="app-store-badges-wrapper footer">
                        <AppStoreBadges />
                    </div>
                    <div className="footer-links">
                        <a target="blank" href={SUITS_ME_ABOUT_US}>
                            {translate('footer.about')}
                        </a>
                        <span />
                        <a target="blank" href={SUITS_ME_SECURITY_POLICY}>
                            {translate('footer.security')}
                        </a>
                        <span />
                        <a target="blank" href={SUITS_ME_PRIVACY_POLICY}>
                            {translate('footer.privacy')}
                        </a>
                        <span />
                        <a target="blank" href={SUITS_ME_TERMS_AND_CONDITIONS}>
                            {translate('footer.tsAndCs')}
                        </a>
                        <span />
                        <a target="blank" href={SUITS_ME_ACCESSIBILITY}>
                            {translate('footer.accessibility')}
                        </a>
                    </div>
                    <div className="copyright">
                        <Typography>&copy; 2021 Suits Me Limited</Typography>
                    </div>
                </PageContainer>
            </div>
        </div>
    );
};

export default LoginTemplate;
