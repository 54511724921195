import { createReducer } from '@reduxjs/toolkit';
import {
    twFetchRecipientsRequest,
    twFetchRecipientsSuccess,
    twFetchRecipientsFailure,
    twFetchTransactRecipientsRequest,
    twFetchTransactRecipientsSuccess,
    twFetchTransactRecipientsFailure,
    twFetchRecipientRequirementsRequest,
    twFetchRecipientRequirementsSuccess,
    twFetchRecipientRequirementsFailure,
    twCreateRecipientRequest,
    twCreateRecipientSuccess,
    twCreateRecipientFailure,
    twPostRecipientRequirementsSuccess,
    twPostRecipientRequirementsRequest,
    twPostRecipientRequirementsFailure,
    twCreateRecipientResetForm,
    twSelectRecipient,
    twClearRecipient,
    updateSelectedAccountSuccess,
} from '../actions';

const initialState = {
    isFetching: false,
    isPosting: false,
    createSuccess: false,
    isPostingRequirements: false,
    error: null,
    selectedId: null,
    transactRecipients: {},
    recipients: {},
    requirements: [],
};

export default createReducer(initialState, {
    [twFetchRecipientsRequest]: handleFetchRequest,
    [twFetchRecipientsSuccess]: handleFetchRecipientsSuccess,
    [twFetchRecipientsFailure]: handleFetchFailure,
    [twFetchTransactRecipientsRequest]: handleFetchRequest,
    [twFetchTransactRecipientsSuccess]: handleFetchTransactRecipientsSuccess,
    [twFetchTransactRecipientsFailure]: handleFetchFailure,
    [twFetchRecipientRequirementsRequest]: handleFetchRequest,
    [twFetchRecipientRequirementsSuccess]: handleFetchRecipientRequirementsSuccess,
    [twPostRecipientRequirementsRequest]: handlePostRequirementsRequest,
    [twPostRecipientRequirementsFailure]: handlePostRequirementsFailure,
    [twPostRecipientRequirementsSuccess]: handlePostRequirementsSuccess,
    [twFetchRecipientRequirementsFailure]: handleFetchFailure,
    [twCreateRecipientRequest]: handleCreateRequest,
    [twCreateRecipientSuccess]: handleCreateSuccess,
    [twCreateRecipientFailure]: handleCreateFailure,
    [twCreateRecipientResetForm]: handleResetCreateForm,
    [twSelectRecipient]: handleSelectRecipient,
    [twClearRecipient]: handleClearRecipient,
    [updateSelectedAccountSuccess]: state => {
        state.recipients = {};
    },
});

function handleSelectRecipient(state, action) {
    state.selectedId = action.payload;
}

function handleResetCreateForm(state) {
    state.isPosting = false;
    state.createSuccess = false;
}

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchRecipientsSuccess(state, action) {
    state.isFetching = false;
    state.recipients = action.payload;
}

function handleFetchTransactRecipientsSuccess(state, action) {
    state.isFetching = false;
    state.transactRecipients = action.payload;
}

function handleFetchRecipientRequirementsSuccess(state, action) {
    state.isFetching = false;
    state.requirements = action.payload;
}

function handlePostRequirementsRequest(state) {
    state.isPostingRequirements = true;
}

function handlePostRequirementsSuccess(state, action) {
    state.isPostingRequirements = false;
    state.requirements = action.payload;
}

function handlePostRequirementsFailure(state, action) {
    state.isPostingRequirements = false;
    state.error = action.payload;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleCreateRequest(state) {
    state.isPosting = true;
    state.createSuccess = false;
    state.error = null;
}

function handleCreateSuccess(state, action) {
    state.isPosting = false;
    state.createSuccess = true;
    state.selectedId = action.payload.id;
    state.recipients[action.payload.id] = action.payload;
    state.transactRecipients[action.payload.id] = action.payload.fullName;
}

function handleCreateFailure(state, action) {
    state.isPosting = false;
    state.createSuccess = false;
    state.error = action.payload;
}

function handleClearRecipient(state) {
    state.recipients = {};
    state.selectedId = null;
    state.requirements = [];
    state.createSuccess = false;
}
