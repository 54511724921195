import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors, setJwtHeader } from '../utils';

// Logout
export const logout = createAction('LOGOUT');

export const postLogin = createLoginStepAction('post', 'login');
export const activateAccount = createLoginStepAction('put', 'activate');
export const createPassword = createLoginStepAction('put', 'password');
export const createPassCode = createLoginStepAction('put', 'security-code');
export const createSecurityQuestions = createLoginStepAction('post', 'security-questions');
export const validatePassCode = createLoginStepAction('post', 'security-code/authenticate');

// Login steps
export const postLoginStepRequest = createAction('postLoginStepRequest');
export const postLoginStepSuccess = createAction('postLoginStepSuccess');
export const postLoginStepFailure = createAction('postLoginStepFailure');

function createLoginStepAction(apiMethod, url) {
    return postBody => async dispatch => {
        dispatch(postLoginStepRequest(url));

        try {
            const { data } = await api[apiMethod](`login-steps/${url}`, postBody);
            if (data.token) setJwtHeader(data.token);
            dispatch(postLoginStepSuccess(data));
        } catch (e) {
            handleErrors(dispatch, postLoginStepFailure, e, true);
        }
    };
}

export const postForgotPasswordRequest = createAction('postForgotPasswordRequest');
export const postForgotPasswordSuccess = createAction('postForgotPasswordSuccess');
export const postForgotPasswordFailure = createAction('postForgotPasswordFailure');

export const postForgotPassword = postBody => async dispatch => {
    dispatch(postForgotPasswordRequest());

    try {
        await api.post(`auth/forgot-password`, postBody);
        dispatch(postForgotPasswordSuccess());
    } catch (e) {
        handleErrors(dispatch, postForgotPasswordFailure, e, true);
    }
};

export const postForgotPasscodeRequest = createAction('postForgotPasscodeRequest');
export const postForgotPasscodeSuccess = createAction('postForgotPasscodeSuccess');
export const postForgotPasscodeFailure = createAction('postForgotPasscodeFailure');

export const postForgotPasscode = postBody => async dispatch => {
    dispatch(postForgotPasscodeRequest());

    try {
        await api.post(`auth/forgot-passcode`, postBody);
        dispatch(postForgotPasscodeSuccess());
    } catch (e) {
        handleErrors(dispatch, postForgotPasscodeFailure, e, true);
    }
};
