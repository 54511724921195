export const EDIT_PERSONAL_DETAILS_FIELDS = [
    {
        name: 'Email',
        slug: 'email',
        initialValue: '',
        field: 'text',
        type: 'text',
        required: true,
        keyboardType: 'email-address',
    },
    {
        name: 'Address Line 1',
        slug: 'addressLine1',
        initialValue: '',
        field: 'text',
        required: true,
    },
    {
        name: 'Address Line 2',
        slug: 'addressLine2',
        initialValue: '',
        field: 'text',
        required: false,
    },
    {
        name: 'Town',
        slug: 'town',
        initialValue: '',
        field: 'text',
        required: true,
    },
    {
        name: 'Country',
        slug: 'country',
        initialValue: '',
        field: 'text',
        required: true,
    },
    {
        name: 'Post Code',
        slug: 'postcode',
        initialValue: '',
        field: 'text',
        required: true,
    },
    {
        name: 'Telephone 1',
        slug: 'tel1',
        initialValue: '',
        field: 'text',
        required: true,
        charLimit: '15',
        keyboardType: 'phone-pad',
    },
    {
        name: 'Telephone 2',
        slug: 'tel2',
        initialValue: '',
        field: 'text',
        required: false,
        charLimit: '15',
        keyboardType: 'phone-pad',
    },
];

export const MAKE_PAYMENT_FIELDS = [
    {
        name: 'Amount',
        initialValue: '',
        field: 'currency',
        prefix: '£',
        prefixValue: '£',
        placeholder: 'Amount',
        type: 'number',
        keyboardType: 'decimal-pad',
        required: true,
    },
    {
        name: 'PaymentReference',
        initialValue: '',
        field: 'text',
        placeholder: 'Reference',
        required: true,
        maxLength: 18,
    },
];

export const MAKE_STANDING_ORDER_FIELDS = [
    {
        name: 'Amount',
        initialValue: '',
        field: 'currency',
        prefix: '£',
        prefixValue: '£',
        placeholder: 'Amount',
        type: 'number',
        keyboardType: 'decimal-pad',
        required: true,
    },
    {
        name: 'StartOn',
        initialValue: new Date(),
        field: 'date',
        placeholderText: 'Date to Send',
        placeholder: 'Date Start',
        required: true,
    },
    {
        name: 'EndOn',
        initialValue: new Date(),
        field: 'date',
        placeholderText: 'Date to End',
        placeholder: 'Date End',
    },
    {
        name: 'RecurType',
        initialValue: '',
        field: 'select',
        placeholder: 'Recurring Type',
        options: [
            { label: 'Daily', value: '1' },
            { label: 'Weekly', value: '2' },
            { label: 'Monthly', value: '3' },
            { label: 'Yearly', value: '4' },
        ],
        required: true,
    },
    {
        name: 'Reference',
        initialValue: '',
        field: 'text',
        placeholder: 'Reference',
        required: true,
        maxLength: 18,
    },
];
