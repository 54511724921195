import Button from 'components/shared/generic/button/presentational/Button';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearFieldErrors,
    clearFormError,
    showFieldErrors,
} from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import { getFieldErrors, getFormError } from 'lib/selectors';
import { isEmpty } from 'lib/utils';
import analytics from 'utils/analytics';
import Field from './Field';

const Form = ({
    className = '',
    styles,
    children,
    error,
    omitButtons = false,
    isPosting,
    submitText,
    cancelText,
    buttonSize = 'normal',
    buttonMt = '16px',
    onSubmit,
    onCancel,
    tracking,
    trackingAction = 'FORM_CLICK',
    trackingLabel = 'FORM_SUBMIT',
    disabledButton = false,
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const genericError = useSelector(getFormError);

    useEffect(() => {
        return () => {
            dispatch(clearFormError());
            dispatch(clearFieldErrors());
        };
    }, []);

    const translate = useTranslate('generic');
    return (
        <form
            className={`sm-form ${className}`}
            style={styles}
            onSubmit={_handleSubmit}
        >
            {children}
            {!!genericError && (
                <Field>
                    <p className="error">{genericError}</p>
                </Field>
            )}
            {!!error && (
                <Field>
                    <p className="error">{error}</p>
                </Field>
            )}

            {!omitButtons && (
                <ButtonContainer marginTop={buttonMt}>
                    {!!onCancel && (
                        <Button
                            type="button"
                            className="cancel"
                            onClick={_handleCancel}
                            disabled={isPosting}
                        >
                            {cancelText || translate('close')}
                        </Button>
                    )}
                    <Button
                        size={buttonSize}
                        isPosting={isPosting}
                        type="submit"
                        className="form-submit center"
                        disabled={disabledButton}
                    >
                        {submitText || translate('confirm')}
                    </Button>
                </ButtonContainer>
            )}
        </form>
    );

    function _handleCancel(e) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e) {
        e.preventDefault();

        if (tracking) {
            analytics.sendFormEvent(trackingAction, trackingLabel);
        }

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

export default Form;
