import axios from 'axios';
import { isEmpty } from '.';
import {
    setFieldErrors,
    logout,
    addGenericFormError,
    setGenericError,
    showAccountBlockedModal,
} from '../actions';

let API_URL = '';
const timeout = 15 * 1000; // 15 seconds
axios.defaults.timeout = timeout;

const config = {
    headers: {
        Authorization: null,
    },
    timeout,
};

let handleErrorsCb = () => {};
const makeURL = url => `${API_URL}/${url.replace(/^\/+/, '')}`;

async function axiosWithTimeout(options) {
    let reqTimeout;
    const controller = new AbortController();
    const signal = controller.signal;
    reqTimeout = setTimeout(() => controller.abort(), timeout);
    const response = await axios({ signal, ...options });
    clearTimeout(reqTimeout);
    return response;
}

export const api = {
    get: url => axiosWithTimeout({ method: 'get', url: makeURL(url), ...config }),
    post: (url, data) => axiosWithTimeout({ method: 'post', url: makeURL(url), data, ...config }),
    put: (url, data) => axiosWithTimeout({ method: 'put', url: makeURL(url), data, ...config }),
    patch: (url, data) => axiosWithTimeout({ method: 'patch', url: makeURL(url), data, ...config }),
    delete: url => axiosWithTimeout({ method: 'delete', url: makeURL(url), ...config }),
    download: url => axiosWithTimeout({ method: 'get', url: makeURL(url), ...config, responseType: 'blob' }),
};

export const init = (baseURL, jwt = null, headers = {}, errorCb = () => {}) => {
    API_URL = baseURL;
    config.headers = { ...config.headers, ...headers };
    setJwtHeader(jwt);
    handleErrorsCb = errorCb;
};

export function setJwtHeader(jwt) {
    if (!jwt) config.headers.Authorization = null;
    else config.headers.Authorization = `Bearer ${jwt}`;
}

function is5xxError(status) {
    const _status = '' + status;
    return _status.startsWith('5');
}

export function handleErrors(dispatch, failureAction, err, enableGenericError = false) {
    const { response, message } = err;

    if (response && response.status === 400) {
        if (typeof response == 'string') {
            dispatch(addGenericFormError(response));
            return dispatch(failureAction(null));
        } else if (typeof response.data === 'string') {
            dispatch(addGenericFormError(response.data));
            return dispatch(failureAction(null));
        } else {
            dispatch(setFieldErrors(formatFieldErrors(response.data.errors)));
            return dispatch(failureAction(null));
        }
    }

    if (handleErrorsCb) {
        handleErrorsCb(err);
    }
    if (response && response.status == 401) {
        dispatch(failureAction('Unauthorized'));
        return dispatch(logout());
    }
    if (response && response.status == 403) {
        dispatch(failureAction('Forbidden'));
        return dispatch(logout());
    }
    if (response && response.status == 451) {
        dispatch(failureAction(null));
        return dispatch(showAccountBlockedModal());
    }
    if (response && is5xxError(response.status) && enableGenericError) {
        dispatch(failureAction('There seems to be a connectivity problem. Please try again.'));
        return dispatch(
            setGenericError('There seems to be a connectivity problem. Please try again.'),
        );
    }

    return dispatch(failureAction(message));
}

function formatFieldErrors(errors) {
    if (isEmpty(errors)) return {};

    return Object.keys(errors).reduce((acc, key) => {
        const value = errors[key];

        if (isEmpty(value)) return acc;
        if (typeof value === 'string') acc[key] = value;
        if (Array.isArray(value)) acc[key] = value.join(', ');

        return acc;
    }, {});
}

export function postMedia(formData) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    return api.post('media', formData, { headers });
}
