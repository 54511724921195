import { useDispatch, useSelector } from 'react-redux';
import { getGenericError } from 'lib/selectors/genericErrors';
import { usePrevious } from 'lib/utils';
import { useEffect } from 'react';
import { dispatchShowModal, dispatchHideModal } from 'utils/modals';
import { GENERIC_5XX_ERROR_MODAL } from 'constants/shared/modalTypes';
import { useHistory } from 'react-router-dom';
import { clearGenericError } from 'lib/actions';

const useGenericErrorModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const genericError = useSelector(getGenericError);
    const prevProps = usePrevious({ genericError });

    const handleGoBack = () => {
        dispatch(clearGenericError());
        dispatchHideModal(dispatch);
        history.goBack();
    };

    useEffect(() => {
        if (genericError && !prevProps.genericError)
            dispatchShowModal(dispatch, GENERIC_5XX_ERROR_MODAL, {
                message: genericError,
                goBack: handleGoBack,
                hideModal: () => dispatchHideModal(dispatch),
            });
    }, [genericError, prevProps.genericError]);

    useEffect(() => {
        if (!genericError && prevProps.genericError)
            dispatchHideModal(dispatch);
    }, [genericError, prevProps.genericError]);
};

export default useGenericErrorModal;
