import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { getTheme } from 'selectors/darkMode';

import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

export default function HelpButton() {
    const [rcBadge, setRcBadge] = React.useState(null);
    const isDarkModeEnabled = useSelector(getTheme);

    new MutationObserver(() => {
        setRcBadge(document.getElementsByClassName('grecaptcha-badge').item(0));
    }).observe(document.body, { childList: true });

    const translate = useTranslate('bottomTabs')
    const nodes = (
        <Button
            href="https://support.suitsmecard.com/hc/en-gb/requests/new"
            className={`help-button ${isDarkModeEnabled ? 'dark' : ''} ${
                rcBadge != null ? 'rc-offset' : ''
            }`}
        >
            {translate('help')}
        </Button>
    );

    const element = document.getElementById('root');
    if (element == null) return null;

    return createPortal(nodes, element);
}
