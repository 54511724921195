import React from 'react';
import { Redirect } from 'react-router-dom';

import { validateJWT } from 'lib/utils';

export default function(ProtectedComponent) {
    class WithAuth extends React.Component {
        state = {
            checkComplete: false,
            isAuthorized: false,
        };

        render() {
            const { checkComplete, isAuthorized } = this.state;

            if (!checkComplete) return null;
            if (!isAuthorized) return <Redirect to="/auth/login" />;

            return <ProtectedComponent curUrl={this.props.location.pathname} {...this.props} />;
        }

        componentDidMount() {
            this.setState({
                checkComplete: true,
                isAuthorized: validateJWT(localStorage.getItem('token')),
            });
        }
    }

    return WithAuth;
}
