import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postForgotPassword } from 'lib/actions';
import { useForm } from 'lib/utils';
import { getAuthError, getAuthIsPosting } from 'lib/selectors';
import { RECOVERY_SUCCESS } from 'constants/shared/routes';
import LoginTemplate from 'components/shared/layout/loginTemplate/presentational/LoginTemplate';
import PasswordRecovery from '../presentational/PasswordRecovery';

const PasswordRecoveryContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isPosting = useSelector(getAuthIsPosting);
    const error = useSelector(getAuthError);

    const [form, handleChange] = useForm({
        username: '',
    });

    const handleSubmit = () => {
        dispatch(postForgotPassword({ username: form.username.trim() })).then(
            () => {
                history.push(RECOVERY_SUCCESS);
            },
        );
    };

    return (
        <LoginTemplate>
            <PasswordRecovery
                {...form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isPosting={isPosting}
                error={error}
            />
        </LoginTemplate>
    );
};

export default PasswordRecoveryContainer;
