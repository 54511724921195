import { useCallback, useEffect, useRef, useState } from 'react';

export function usePrevious(value) {
    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function useForm(initialData) {
    const [formData, setFormData] = useState(initialData);

    const handleChange = useCallback(
        (name, value) => {
            setFormData(prev => ({ ...prev, [name]: value }));
        },
        [setFormData],
    );

    const resetData = useCallback(
        data => {
            setFormData(data);
        },
        [setFormData],
    );

    return [formData, handleChange, resetData];
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
}
