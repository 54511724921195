import { createAction } from '@reduxjs/toolkit';
import { getTransferWiseLanguageCode } from '../selectors/languages';

import { api, handleErrors } from '../utils';

// Create temporary quote
export const twCreateTemporaryQuoteRequest = createAction('TW_CREATE_TEMPORARY_QUOTE_REQUEST');
export const twCreateTemporaryQuoteSuccess = createAction('TW_CREATE_TEMPORARY_QUOTE_SUCCESS');
export const twCreateTemporaryQuoteFailure = createAction('TW_CREATE_TEMPORARY_QUOTE_FAILURE');

export const twCreateTemporaryQuote = postBody => async (dispatch, getState) => {
    dispatch(twCreateTemporaryQuoteRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(
            `/transferwise/quotes/temporary?language=${language}`,
            postBody,
        );

        return dispatch(twCreateTemporaryQuoteSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twCreateTemporaryQuoteFailure, e);
    }
};

// Create quote
export const twCreateQuoteRequest = createAction('TW_CREATE_QUOTE_REQUEST');
export const twCreateQuoteSuccess = createAction('TW_CREATE_QUOTE_SUCCESS');
export const twCreateQuoteFailure = createAction('TW_CREATE_QUOTE_FAILURE');

export const twCreateQuote = postBody => async (dispatch, getState) => {
    dispatch(twCreateQuoteRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(`/transferwise/quotes?language=${language}`, postBody);

        return dispatch(twCreateQuoteSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twCreateQuoteFailure, e);
    }
};

// Update quote
export const twUpdateQuoteRequest = createAction('TW_UPDATE_QUOTE_REQUEST');
export const twUpdateQuoteSuccess = createAction('TW_UPDATE_QUOTE_SUCCESS');
export const twUpdateQuoteFailure = createAction('TW_UPDATE_QUOTE_FAILURE');

export const twUpdateQuote = (quoteID, postBody) => async (dispatch, getState) => {
    dispatch(twUpdateQuoteRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.put(
            `/transferwise/quotes/${quoteID}?language=${language}`,
            postBody,
        );

        return dispatch(twUpdateQuoteSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twUpdateQuoteFailure, e);
    }
};

// Clear temporary and final quotes
export const twClearAllQuotes = createAction('TW_CLEAR_ALL_QUOTES');
