import React from 'react';
import dayjs from 'dayjs';
import { formattedCardNumber } from 'lib/utils';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const FrozenCard = ({ position, onClick, info, isDarkText }) => {
    const cardNumber = formattedCardNumber(info.cardPan);
    const translate = useTranslate('cards');
    return (
        <div className={`content frozen-card ${isDarkText ? 'dark-text' : ''}`}>
            <i className="icon fa fa-lock-alt"></i>
            <p className="expiry-date">
                {dayjs(info.expiryDate).format('YY/MM')}
            </p>
            <span className="card-number">{cardNumber}</span>
            <p className="name">{info.cardHolderName}</p>
            <p className="title">{translate('cardFrozen')}</p>
            {position === 'center' && (
                <Button onClick={onClick}>
                    {translate('orderReplacement')}
                </Button>
            )}
        </div>
    );
};

export default FrozenCard;
