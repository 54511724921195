import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

export const fetchCommunicationPreferencesRequest = createAction(
    'FETCH_COMMUNICATION_PEFERENCES_REQUEST',
);
export const fetchCommunicationPreferencesSuccess = createAction(
    'FETCH_COMMUNICATION_PEFERENCES_SUCCESS',
);
export const fetchCommunicationPreferencesFailure = createAction(
    'FETCH_COMMUNICATION_PEFERENCES_FAILURE',
);

export const fetchCommunicationPreferences = () => async dispatch => {
    dispatch(fetchCommunicationPreferencesRequest());

    try {
        const { data } = await api.get('/account/comms-preferences');
        return dispatch(fetchCommunicationPreferencesSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchCommunicationPreferencesFailure, e, true);
    }
};

export const updateCommunicationPreferencesRequest = createAction(
    'UPDATE_COMMUNICATION_PEFERENCES_REQUEST',
);
export const updateCommunicationPreferencesSuccess = createAction(
    'UPDATE_COMMUNICATION_PEFERENCES_SUCCESS',
);
export const updateCommunicationPreferencesFailure = createAction(
    'UPDATE_COMMUNICATION_PEFERENCES_FAILURE',
);

export const updateCommunicationPreferences = comms => async dispatch => {
    dispatch(updateCommunicationPreferencesRequest());

    try {
        const { data } = await api.put('/account/comms-preferences', { ...comms });
        return dispatch(updateCommunicationPreferencesSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, updateCommunicationPreferencesFailure, e, true);
    }
};
