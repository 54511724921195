import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getLanguage,
    getPricePlans,
    getPricePlansIsFetching,
    getPricePlansError,
} from '../selectors';
import { fetchPricePlans } from '../actions';

const useFetchPricePlans = () => {
    const dispatch = useDispatch();
    const languageCode = useSelector(getLanguage);
    console.log(languageCode);

    useEffect(() => {
        if (languageCode.length) {
            dispatch(fetchPricePlans(languageCode));
        }
    }, [dispatch, languageCode, fetchPricePlans]);

    const pricePlans = useSelector(getPricePlans);
    const isFetching = useSelector(getPricePlansIsFetching);
    const error = useSelector(getPricePlansError);

    return { pricePlans, isFetching, error };
};

export default useFetchPricePlans;
