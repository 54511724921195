import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchZendeskCategories } from 'lib/actions';
import {
    getZendeskCategories,
    getZendeskError,
    getZendeskIsFetching,
} from 'lib/selectors';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import ZendeskFAQs from '../presentational/ZendeskFAQs';
import useTranslate from 'lib/languages/useTranslate';

const ZendeskFAQsContainer = () => {
    const dispatch = useDispatch();
    const faqs = useSelector(getZendeskCategories);
    const isFetching = useSelector(getZendeskIsFetching);
    const error = useSelector(getZendeskError);

    const translate = useTranslate('faqs')

    useEffect(() => {
        dispatch(fetchZendeskCategories());
    }, []);

    return (
        <DataCheck
            data={faqs}
            isLoading={isFetching}
            error={error}
            noDataMessage={translate('noDataMessage')}
        >
            <ZendeskFAQs faqs={faqs} />
        </DataCheck>
    );
};

export default ZendeskFAQsContainer;
