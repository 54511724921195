import React from 'react';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../containers/ModalOuterContainer';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const GenericErrorModal = ({
    message,
    title,
    goBack = () => {},
    hideModal = () => {},
}) => {
    const translate = useTranslate('generic');
    const defaultMessage = translate('connectivityProblem')
    const defaultTitle = translate('error')
    return (
        <ModalOuterContainer className="status-modal" customButtons>
            <div className="description">
                <div className="modal-icon">
                    <i className="fa fa-times-circle" />
                </div>
                <Typography tag="h1">{title ?? defaultTitle}</Typography>
                <Typography className="generic-text intro-text">
                    {message ?? defaultMessage}
                </Typography>
            </div>
            <ButtonContainer className="center">
                <Button className="cancel" onClick={hideModal}>
                    {translate('cancel')}
                </Button>
                <Button onClick={goBack}>{translate('back')}</Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default GenericErrorModal;
