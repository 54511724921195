import React, { useState } from 'react';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import TextInput from 'components/shared/form/presentational/TextInput';
import PasswordMeter from 'components/shared/form/presentational/PasswordMeter';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';

const Password = ({
    password,
    confirmPassword,
    handleChange,
    handleSubmit,
    isPosting,
    error,
    hidePasswordTitle,
}) => {
    const translate = useTranslate('setPassword');

    const canSubmit =
        password &&
        confirmPassword &&
        password === confirmPassword;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <Box className="password-container">
            {!hidePasswordTitle && (
                <div className="statement-container">
                    <SectionTitle title={translate('titleText')} secondary />
                    <div className="statement mb">
                        <Typography>{translate('descriptionText')}</Typography>
                    </div>
                </div>
            )}
            <Form
                className="password-form"
                submitText={translate('form.confirm')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? `${translate('form.errorMessage')}` : ''}
                disabledButton={!canSubmit}
            >
                <Field required>
                    <TextInput
                        required
                        placeholder={translate('form.password')}
                        onChange={handleChange}
                        name="password"
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        classes="generic-input"
                        minLength={10}
                        appendIcon={
                            <i
                                className="fa fa-eye"
                                onClick={() => setShowPassword(state => !state)}
                            />
                        }
                    />
                </Field>

                <Field required>
                    <TextInput
                        required
                        placeholder={translate('form.confirmPassword')}
                        onChange={handleChange}
                        name="confirmPassword"
                        value={confirmPassword}
                        type={showConfirmPassword ? 'text' : 'password'}
                        classes="generic-input"
                        minLength={10}
                        appendIcon={
                            <i
                                className="fa fa-eye"
                                onClick={() =>
                                    setShowConfirmPassword(state => !state)
                                }
                            />
                        }
                    />
                </Field>
                <PasswordMeter password={password} />
            </Form>
        </Box>
    );
};

export default Password;
