export const LOGIN = '/auth/login';
export const FORGOTTEN_USERNAME = '/auth/forgotten-username';
export const FORGOTTON_PASSWORD = '/auth/forgotten-password';
export const RECOVERY_SUCCESS = '/auth/recovery-success';

export const TRANSACTIONS = '/';

export const PAYMENT = '/payments';
export const PAYMENT_MAKE_PAYMENT = '/payments/make-payment';
export const PAYMENT_SEND_PAYMENT = '/payments/send-payment';
export const PAYMENT_STANDING_ORDER = '/payments/standing-order';
export const PAYMENT_DIRECT_DEBIT = '/payments/direct-debit';
export const PAYMENT_MANAGE_PAYEES = '/payments/manage-payees';
export const PAYMENT_ADD_PAYEE = '/payments/add-payee';
export const PAYMENT_ADD_CASH = '/payments/add-cash';

export const PAYMENT_MAKE_PAYMENT_FORM = '/payments/make-payment/form';
export const PAYMENT_MAKE_PAYMENT_SUCCESS = '/payments/make-payment/success';

export const PAYMENT_STANDING_ORDER_PAYEE = '/payments/standing-order/payee';
export const PAYMENT_STANDING_ORDER_FORM = '/payments/standing-order/form';
export const PAYMENT_STANDING_ORDER_SUCCESS =
    '/payments/standing-order/success';

export const TRANSFERWISE_INITIAL_QUOTE = '/international/initial-quote';
export const TRANSFERWISE_CHECK = '/international/check';
export const TRANSFERWISE_REVIEW_QUOTE = '/international/review-quote';
export const TRANSFERWISE_SELECT_RECIPIENT = '/international/select-recipient';
export const TRANSFERWISE_ADD_RECIPIENT = '/international/select-recipient/add';
export const TRANSFERWISE_MORE_DETAILS = '/international/more-details';
export const TRANSFERWISE_FINAL_REVIEW = '/international/review';
export const TRANSFERWISE_STATUS = '/international/status';

export const MORE = '/more';
export const MORE_ACCOUNT_DETAILS = '/more/account';
export const MORE_ADD_FUNDS = '/more/add-funds';
export const MORE_ADD_FUNDS_BANK_TRANSFER = '/more/add-funds/bank-transfer';
export const MORE_ADD_FUNDS_SALARY = '/more/add-funds/salary';
export const MORE_ADD_FUNDS_CASH = '/more/add-funds/cash';
export const MORE_MANAGE_SUBSCRIPTION = '/more/manage-subscription';
export const MORE_PERSONAL_DETAILS = '/more/personal';
export const MORE_EDIT_PERSONAL_DETAILS = `${MORE_PERSONAL_DETAILS}/edit`;
// export const MORE_FEES = '/more/fees';
export const MORE_CONTACT_US = '/more/contact-us';
export const MORE_COMMUNICATION_PREFERENCES = '/more/communication-preferences';
export const MORE_SETTINGS = '/more/settings';
export const MORE_CHANGE_PASSWORD = '/more/change-password';
export const MORE_ZENDESK_FAQ = '/more/zendesk-faqs';
export const MORE_REFER_A_FRIEND = '/more/refer-a-friend';

export const CARDS = '/cards';
export const CARDS_PIN_OPTIONS = '/cards/pin-options';

export const HELP = '/help';

export const MORE_CASHBACK_REWARDS = '/more/cashback-rewards';

//external links

export const SUITS_ME_REFER = 'https://suitsmecard.com/features/extras/refer';
export const SUITS_ME_APPLY = 'https://suitsmecard.com/apply';
export const SUITS_ME_VIEW_GUIDE =
    'https://support.suitsmecard.com/hc/en-gb/articles/4409812493329';
export const SUITS_ME_MANAGING_GUIDE =
    'https://support.suitsmecard.com/hc/en-gb/articles/4410597249297';
export const SUITS_ME_RESET_PASSWORD =
    'https://support.suitsmecard.com/hc/en-gb/articles/360010999118';
export const SUITS_ME_CONTACT_US =
    'https://support.suitsmecard.com/hc/en-gb/requests/new';
export const SUITS_ME_BLOCKED_ACCOUNT =
    'https://support.suitsmecard.com/hc/en-gb/articles/360010999138-Access-to-my-online-banking-account-is-blocked';
export const SUITS_ME_ACTIVATE_DEBIT_CARD =
    'https://support.suitsmecard.com/hc/en-gb/articles/360011830497';
export const SUITS_ME_FINANCIAL_ABUSE =
    'https://suitsmecard.com/support/financial-abuse-support-guide';
export const SUITS_ME_ABOUT_US =
    'https://suitsmecard.com/support/who-are-suitsme';
export const SUITS_ME_SECURITY_POLICY = 'https://suitsmecard.com/security';
export const SUITS_ME_PRIVACY_POLICY = 'https://suitsmecard.com/privacy-policy';
export const SUITS_ME_MONEY_MAKERS = 'https://suitsmecard.com/money_makers';
export const SUITS_ME_TERMS_AND_CONDITIONS =
    'https://suitsmecard.com/terms-conditions';
export const SUITS_ME_ACCESSIBILITY = 'https://suitsmecard.com/accessibility';
export const SUITS_ME_TWITTER = 'https://twitter.com/SuitsMeCard';
export const SUITS_ME_FACEBOOK = 'https://www.facebook.com/suitsmecard';
export const SUITS_ME_EMAIL = 'mailto:hello@suitsmecard.com';
export const SUITS_ME_CASHBACK_REWARDS =
    'https://suitsmecard.com/features/cashback-rewards';


export const TRANSFERWISE_CREATE_ACCOUNT =
    'https://transferwise.com/terms-of-use';
export const TRANSFERWISE_PRIVACY_POLICY =
    'https://transferwise.com/gb/legal/privacy-policy';
export const ACCOUNT_HOLDER_DISCOUNTS = 'https://suitsmecard.com/account-holder-discounts'