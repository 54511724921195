import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, usePrevious } from 'lib/utils';
import { createPayee } from 'lib/actions';
import {
    getPayeesIsPosting,
    getPayeesPostError,
    getPayeesPostSuccess,
} from 'lib/selectors/payees';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CreatePayee from '../presentational/CreatePayee';
import useTranslate from 'lib/languages/useTranslate';

const CreatePayeeContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const error = useSelector(getPayeesPostError);
    const isPosting = useSelector(getPayeesIsPosting);
    const postSuccess = useSelector(getPayeesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const backRoute = location.state ? location.state.backRoute : null;
    const translate = useTranslate('addPayee');

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            history.goBack();
        }
    }, [postSuccess, prevPostSuccess]);

    const [formData, handleChange] = useForm({
        payeeName: '',
        sortCode: '',
        payeeAccountNo: '',
        password: '',
    });

    const handleSubmit = () => {
        const { payeeName, sortCode, payeeAccountNo, password } = formData;

        const postBody = {
            payeeName,
            sortCode: sortCode.replace(/-/g, ''),
            payeeAccountNo: payeeAccountNo.replace(/\s/g, ''),
            password,
        };

        dispatch(createPayee(postBody));
    };

    return (
        <PageContainer className="manage-payee-container">
            <SectionTitle
                title={translate('titleText')}
                backRoute={backRoute}
            />
            <CreatePayee
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                isPosting={isPosting}
            />
        </PageContainer>
    );
};

export default CreatePayeeContainer;
