import React from 'react';

import useTranslate from 'lib/languages/useTranslate';

import { Link } from 'react-router-dom';

import { formatMoney } from 'lib/utils';

const PendingTransactionCard = ({ item }) => {
    const translate = useTranslate('pendingTransactions');

    return (
        <div className="transaction-container">
            <Link to="/activity/pending">
                <div className="transaction-toggle">
                    <div className="transaction-left">
                        <i className="far fa-clock" />
                        <p className="transaction-name">
                            {translate('mainListText', { count: item.count })}
                        </p>
                    </div>
                    <div className="transaction-right">
                        <div className="transaction-item-container">
                            <p className="transaction-type"></p>
                        </div>
                        <div className="transaction-item-container">
                            <p
                                className={`transaction-amount ${
                                    item.value > 0 ? 'green' : ''
                                }`}
                            >
                                {`${formatMoney(item.amount)} GBP`}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default PendingTransactionCard;
