import React from 'react';

import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { formatMoney } from 'lib/utils';

const MakePaymentText = ({ selectedPayee, amount, paymentReference }) => {
    const translate = useTranslate('makePayment');
    return (
        <>
            <Typography>{translate('sendAndConfirm.line1')}</Typography>
            <Typography tag="h2">£{formatMoney(amount * 100)}</Typography>
            <Typography>{translate('sendAndConfirm.line2')}</Typography>
            <Typography tag="h2">{selectedPayee.payeeName}</Typography>
            <Typography tag="h2">
                {translate('sendAndConfirm.line3')} {selectedPayee.accountNo}
            </Typography>
            <Typography tag="h2">{selectedPayee.sortCode}</Typography>
            <Typography>{translate('sendAndConfirm.line4')}</Typography>
            <Typography tag="h2">"{paymentReference}"</Typography>
            <Typography>{translate('sendAndConfirm.line5')}</Typography>
        </>
    );
};

export default MakePaymentText;
