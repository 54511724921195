import { languageCodes } from '../constants';

import english from './en-gb.json';
import german from './de.json';
import french from './fr.json';
import bulgarian from './bg.json';
import romanian from './ro.json';
import polish from './pl.json';

export default {
    [languageCodes.ENGLISH]: english,
    [languageCodes.GERMAN]: german,
    [languageCodes.FRENCH]: french,
    [languageCodes.BULGARIAN]: bulgarian,
    [languageCodes.ROMANIAN]: romanian,
    [languageCodes.POLISH]: polish,
};
