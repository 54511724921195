import dayjs from 'dayjs';
import replaceSpecialCharacters from 'replace-special-characters';

function isObjEmpty(obj) {
    for (var key in obj) {
        const keyExists = Object.prototype.hasOwnProperty.call(obj, key);
        if (keyExists) return false;
    }
    return true;
}

export function isEmpty(item) {
    if (item instanceof Date) return isNaN(item);
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item;
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

export function convertArrToObj(arr, field = 'id') {
    return arr.reduce((acc, item) => {
        acc[item[field]] = item;
        return acc;
    }, {});
}

export function formatSortCode(sortCode) {
    if (!sortCode) return null;
    return sortCode.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');
}

export function areArraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) return arr1 === arr2;
    return (
        arr1.length === arr2.length &&
        arr1.every(item => arr2.includes(item)) &&
        arr2.every(item => arr1.includes(item))
    );
}

export function maskedCardNumber(cardNumber) {
    if (!cardNumber) return null;

    const maskedNumber = cardNumber.replace(/.(?=.{4})/g, '*');

    return maskedNumber.replace(/(.{4})/g, '$1 ');
}

export function formattedCardNumber(cardNumber) {
    if (!cardNumber) return null;

    return cardNumber.replace(/(.{4})/g, '$1 ');
}

export function formatMoney(amount) {
    const number = +amount / 100;

    if (isNaN(amount)) return `Error formatting  currency ${amount}.`;

    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formattedTransactions(transactions) {
    if (isEmpty(transactions) || !transactions) return [];

    const response = transactions.reduce((result, value) => {
        const date = dayjs(value.createdDate.split(' ')[0]).format('YYYYMMDD');

        if (!result[date]) {
            result = { ...result, [date]: [value] };
        } else {
            const dateArr = result[date];
            result = { ...result, [date]: [...dateArr, value] };
        }

        return result;
    }, {});

    const sorted = Object.keys(response).sort((a, b) => b.localeCompare(a));
    return sorted.reduce(
        (res, key) => ({
            ...res,
            [key]: response[key].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
        }),
        {},
    );
}

export function formattedRecipients(arr) {
    return arr.reduce((result, value) => {
        return { ...result, [value.id]: value.fullName };
    }, {});
}
export function addFullNameTransactions(arr, recipients) {
    return arr.map(item => {
        return { ...item, fullName: recipients[item.targetAccount] };
    });
}

export function dateToMonthYear(dateStr) {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const year = `${date.getFullYear()}`.slice(2);
    return `${month}/${year}`;
}

export const reverseEnum = obj =>
    Object.keys(obj).reduce((acc, key) => {
        const lower = key
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .toLowerCase();

        acc[obj[key]] = `${lower[0].toUpperCase()}${lower.slice(1)}`;
        return acc;
    }, {});

export function encodeQueryData(data) {
    const entries = Object.entries(data);
    if (!entries || entries.length < 1) return '';
    const ret = Array(entries.length);
    for (let i = 0; i < entries.length; i++) {
        const [key, value] = entries[i];
        ret[i] = encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }
    return ret.join('&');
}

export function replaceSpecialChars(value) {
    if (!value || typeof value !== 'string') return value;
    const updatedLetters = replaceSpecialCharacters(value);
    const updatedValue = updatedLetters.replace(/[^a-zA-Z ]/g, '');
    return updatedValue;
}
