import { createReducer } from '@reduxjs/toolkit';
import { convertArrToObj } from '../utils';

import {
    createStandingOrderRequest,
    createStandingOrderSuccess,
    createStandingOrderFailure,
    fetchStandingOrdersRequest,
    fetchStandingOrdersSuccess,
    fetchStandingOrdersFailure,
    updateStandingOrderRequest,
    updateStandingOrderSuccess,
    updateStandingOrderFailure,
    deleteStandingOrderRequest,
    deleteStandingOrderSuccess,
    deleteStandingOrderFailure,
    updateSelectedAccountSuccess,
} from '../actions';

const initialState = {
    standingOrders: {},
    isFetching: false,
    error: null,
    isPosting: false,
    postSuccess: false,
    postError: null,
};

export default createReducer(initialState, {
    [createStandingOrderRequest]: handlePostRequest,
    [createStandingOrderFailure]: handlePostFailure,
    [createStandingOrderSuccess]: handlePostSuccess,
    [fetchStandingOrdersRequest]: handleFetchRequest,
    [fetchStandingOrdersFailure]: handleFetchFailure,
    [fetchStandingOrdersSuccess]: handleFetchSuccess,
    [updateStandingOrderRequest]: handlePostRequest,
    [updateStandingOrderFailure]: handlePostFailure,
    [updateStandingOrderSuccess]: handlePostSuccess,
    [deleteStandingOrderRequest]: handlePostRequest,
    [deleteStandingOrderFailure]: handlePostFailure,
    [deleteStandingOrderSuccess]: handleDeleteSuccess,
    [updateSelectedAccountSuccess]: state => {
        state.standingOrders = {};
    },
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.standingOrders = convertArrToObj(action.payload);
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.standingOrders[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.standingOrders[action.payload.id].isDeleted = true;
}
