import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { USERNAME_REMINDER_LINK } from 'config/index';
import useTranslate from 'lib/languages/useTranslate';
import Button from 'components/shared/generic/button/presentational/Button';
import { useHistory } from 'react-router-dom';

const PasscodeRecovery = ({
    username,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    const translate = useTranslate('passcodeRecovery');

    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <Box>
            <SectionTitle title={translate('title')} secondary />
            <div className="statement mb sm-typography">
                <p>{translate('description')}</p>
            </div>
            <Form
                submitText={translate('sendRecoveryEmail')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? 'A problem occurred! Please try again.' : ''}
            >
                <Field required>
                    <TextInput
                        required
                        placeholder={translate('username')}
                        onChange={handleChange}
                        name="username"
                        value={username}
                        classes="generic-input"
                    />
                </Field>
            </Form>
            <Button className="invisible-backdrop" size="full" onClick={handleGoBack}>
                {translate('cancel')}
            </Button>
            <div className="statement sm-typography">
                {translate('forgottenUserNameText')}{' '}
                <a href={USERNAME_REMINDER_LINK}>
                    {translate('forgottenUserNameLink')}
                </a>
            </div>
        </Box>
    );
};

export default PasscodeRecovery;
