import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { INTERNATIONAL_PAYMENT_URL } from 'lib/constants/routes';

const InternationalPayees = () => {
    const translate = useTranslate('internationalPayment');

    const handleProceed = () => {
        window.open(INTERNATIONAL_PAYMENT_URL, '_blank').focus();
    };

    return (
        <>
            <Typography>{translate('introText')}</Typography>
            <Button className="center" onClick={handleProceed}>
                {translate('proceed')}
            </Button>
        </>
    );
};

export default InternationalPayees;
