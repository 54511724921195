import React from 'react';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { useDispatch } from 'react-redux';
import { hideModal } from 'actions/modal';
import { hideAccountBlockedModal } from 'lib/actions';

const AccountBlockedModal = () => {
    const translate = useTranslate('accountBlocked');
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideAccountBlockedModal());
        dispatch(hideModal());
    };

    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description">
                <Typography tag="h1">{translate('titleText')}</Typography>
                <Typography>{translate('descriptionText')}</Typography>
            </div>
            <Button onClick={handleClose}>{translate('ok')}</Button>
        </ModalOuterContainer>
    );
};

export default AccountBlockedModal;
