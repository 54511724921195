import { createReducer } from '@reduxjs/toolkit';

import {
    fetchDirectDebitRequest,
    fetchDirectDebitSuccess,
    fetchDirectDebitFailure,
    cancelDirectDebitRequest,
    cancelDirectDebitFailure,
    cancelDirectDebitSuccess,
    updateSelectedAccountSuccess,
} from '../actions';

const initialState = {
    isFetching: false,
    error: null,
    directDebits: {},
};

export default createReducer(initialState, {
    [fetchDirectDebitRequest]: handleFetchRequest,
    [fetchDirectDebitFailure]: handleFetchFailure,
    [fetchDirectDebitSuccess]: handleFetchSuccess,
    [cancelDirectDebitRequest]: handlePostRequest,
    [cancelDirectDebitFailure]: handlePostFailure,
    [cancelDirectDebitSuccess]: handlePostCancelSuccess,
    [updateSelectedAccountSuccess]: state => {
        state.directDebits = {};
    },
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;
    state.directDebits = action.payload;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.error = null;
}

function handlePostCancelSuccess(state, action) {
    state.isPosting = false;
    state.directDebits[action.payload.id].status = 'CANCELLED';
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}
