import { createReducer } from '@reduxjs/toolkit';
import { fetchPricePlansRequest, fetchPricePlansSuccess, fetchPricePlansFailure } from '../actions';

const initialState = {
    pricePlans: [],
    isFetching: false,
    error: null,
};

export default createReducer(initialState, {
    [fetchPricePlansRequest]: handleFetchRequest,
    [fetchPricePlansSuccess]: handleFetchSuccess,
    [fetchPricePlansFailure]: handleFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.pricePlans = action.payload;
    state.isFetching = true;
}

function handleFailure(state, action) {
    state.error = action.payload;
    state.isFetching = false;
}
