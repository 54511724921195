import { createReducer } from '@reduxjs/toolkit';
import {
    twCreateTemporaryQuoteFailure,
    twCreateTemporaryQuoteRequest,
    twCreateTemporaryQuoteSuccess,
    twCreateQuoteRequest,
    twCreateQuoteSuccess,
    twCreateQuoteFailure,
    twUpdateQuoteRequest,
    twUpdateQuoteSuccess,
    twUpdateQuoteFailure,
    twClearAllQuotes,
} from '../actions';

const initialState = {
    isPosting: false,
    error: null,
    temporaryQuote: {},
    quote: {},
};

export default createReducer(initialState, {
    [twCreateTemporaryQuoteRequest]: handlePostRequest,
    [twCreateTemporaryQuoteSuccess]: handlePostTempQuoteSuccess,
    [twCreateTemporaryQuoteFailure]: handleFailure,
    [twCreateQuoteRequest]: handlePostRequest,
    [twCreateQuoteSuccess]: handlePostQuoteSuccess,
    [twCreateQuoteFailure]: handleFailure,
    [twUpdateQuoteRequest]: handlePostRequest,
    [twUpdateQuoteSuccess]: handlePostQuoteSuccess,
    [twUpdateQuoteFailure]: handleFailure,
    [twClearAllQuotes]: handleClearQuotes,
});

function handlePostRequest(state) {
    state.isPosting = true;
    state.error = null;
}

function handlePostTempQuoteSuccess(state, action) {
    state.isPosting = false;
    state.temporaryQuote = action.payload;
}

function handlePostQuoteSuccess(state, action) {
    state.isPosting = false;
    state.quote = action.payload;
}

function handleFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}

function handleClearQuotes(state) {
    state.temporaryQuote = {};
    state.quote = {};
}
