import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createCardHolder } from '../actions';
import { usePrevious } from '../utils';
import { getAccountIsPosting, getAccountPostSuccess, selectAddCardHolderForm } from '../selectors';
import { handleAddCardHolderFormChange } from '../actions/addCardHolder';

export default (onSubmit, onError) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getAccountIsPosting);
    const postSuccess = useSelector(getAccountPostSuccess);

    const form = useSelector(selectAddCardHolderForm);

    const handleChange = (name, value) => {
        dispatch(handleAddCardHolderFormChange(name, value));
    };

    const prevProps = usePrevious({ postSuccess, isPosting });

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess && onSubmit) {
            onSubmit();
        } else if (!postSuccess && !isPosting && prevProps.isPosting && onError) {
            onError();
        }
    }, [postSuccess, prevProps.postSuccess]);

    const handleSubmit = () => {
        const postBody = { ...form, Dob: dayjs(form.Dob).format('YYYY-MM-DD') };
        dispatch(createCardHolder(postBody));
    };

    return {
        form,
        isPosting,
        handleChange,
        handleSubmit,
    };
};
