import React from 'react';
import { useDispatch } from 'react-redux';
import { useValidateConfirmPassword } from 'lib/hooks';
import { createPassword } from 'lib/actions';
import { useForm } from 'lib/utils';
import Password from '../presentational/Password';

const PasswordContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();
    const [form, handleChange] = useForm({
        password: '',
        confirmPassword: '',
    });

    const handleSubmit = () => {
        dispatch(createPassword(form));
    };

    return (
        <Password
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};

export default PasswordContainer;
