import React from 'react';

import { formatMoney } from 'lib/utils';

import useTranslate from 'lib/languages/useTranslate';

const AccountBalanceCard = ({ item }) => {
    const translate = useTranslate('accountBalance');

    return (
        <div className="transaction-container">
            <div className="transaction-toggle no-action">
                <div className="transaction-left">
                    <p className="transaction-name">
                        {translate('mainListText')}
                    </p>
                </div>
                <div className="transaction-right">
                    <div className="transaction-item-container">
                        <p className="transaction-type"></p>
                    </div>
                    <div className="transaction-item-container">
                        <p className={'transaction-amount'}>
                            {`${formatMoney(item.amount)} GBP`}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountBalanceCard;
