import { TW_ACCOUNT_STATES } from 'lib/constants';

const { READY, UNKNOWN, CREATING, LINKING_EXISTING } = TW_ACCOUNT_STATES;

export const twLoadingStates = [UNKNOWN, CREATING, LINKING_EXISTING];

export const twLoadingMessageStatus = {
    [UNKNOWN]: 'Checking if user exists...',
    [CREATING]: 'Creating Transferwise Account...',
    [LINKING_EXISTING]: 'Linking Transferwise Account...',
};

export const twLoadingMessageStatusTranslate = {
    [UNKNOWN]: 'unknown',
    [CREATING]: 'creating',
    [LINKING_EXISTING]: 'linkingExisting',
};

export const twCheckStates = {
    LINK: 'LINK',
    READY: 'READY',
};

export const twReadyToTransfer = status => {
    return status === READY;
};

export const twCheckUnknownStatus = status => {
    return status === UNKNOWN;
};
