import jwtDecode from 'jwt-decode';

// validateJWT returns true or false based on whether the jwt is valid or expired.
export function validateJWT(token) {
    try {
        const decoded = jwtDecode(token);
        const isValid = decoded.exp > new Date().valueOf() / 1000;
        return isValid;
    } catch (e) {
        return false;
    }
}

// validateEmail returns true or false based on whether an email is valid.
export function validateEmail(value) {
    const emailRegex =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(value);
}

export const validatePaymentReference = (val = '') => {
    if (val.length > 18) return 'The reference cannot be longer than 18 characters';

    const paymentRegex = /^([A-Za-z0-9 &./-]){1,18}$/;
    if (!paymentRegex.test(val))
        return 'The reference must only contain characters A-Z, 0-9, space, &-./';

    const has6ContiguousAlphaChars = /[a-zA-Z0-9]{6}/.test(val);
    if (!has6ContiguousAlphaChars)
        return 'The reference must contain at least 6 contiguous alphanumeric characters (e.g. A-Z, 0-9)';

    const strippedVal = val.replace(/[^a-z0-9]/gi, '');
    const areCharsDuplicate = /^(.)\1+$/.test(strippedVal.toLowerCase());
    if (areCharsDuplicate) return 'Alphanumeric characters cannot all be the same (e.g. all zeros)';
};
