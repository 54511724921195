import { createReducer } from '@reduxjs/toolkit';
import {
    twPostTransactionRequirementsRequest,
    twPostTransactionRequirementsSuccess,
    twPostTransactionRequirementsFailure,
    twCreateTransactionRequest,
    twCreateTransactionSuccess,
    twCreateTransactionFailure,
    twClearTransactionsDetails,
} from '../actions';

const initialState = {
    isPostingRequirements: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    requirements: [],
};

export default createReducer(initialState, {
    [twCreateTransactionRequest]: handlePostRequest,
    [twCreateTransactionSuccess]: handleCreateSuccess,
    [twCreateTransactionFailure]: handleFailure,
    [twPostTransactionRequirementsRequest]: handlePostRequirementsRequest,
    [twPostTransactionRequirementsSuccess]: handlPostRequirementsSuccess,
    [twPostTransactionRequirementsFailure]: handlePostRequirementsFailure,
    [twClearTransactionsDetails]: handleClearTransactionDetails,
});

function handleFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handleCreateSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
    state.requirements = [];
}

function handlePostRequirementsRequest(state) {
    state.isPostingRequirements = true;
}

function handlPostRequirementsSuccess(state, action) {
    state.isPostingRequirements = false;
    state.requirements = action.payload;
}

function handlePostRequirementsFailure(state, action) {
    state.isPostingRequirements = false;
    state.error = action.payload;
}

function handleClearTransactionDetails(state) {
    state.postSuccess = false;
    state.isPosting = false;
    state.isPostingRequirements = false;
}
