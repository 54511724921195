import { createAction } from '@reduxjs/toolkit';
import {cardPinErrors, convertReasonToStatus, convertStatusToReason} from '../constants';
import { api, handleErrors } from '../utils';
import axios from 'axios';
import { fetchAccount } from './account';

// fetch cards
export const fetchCardsRequest = createAction('fetchCardsRequest');
export const fetchCardsSuccess = createAction('fetchCardsSuccess');
export const fetchCardsFailure = createAction('fetchCardsFailure');

export const fetchCards = () => async dispatch => {
    dispatch(fetchCardsRequest());

    try {
        const { data } = await api.get(`cards`);
        return dispatch(fetchCardsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchCardsFailure, e, true);
        // return dispatch(fetchCardsFailure());
    }
};

// freeze card
export const freezeCardRequest = createAction('freezeCardRequest');
export const freezeCardSuccess = createAction('freezeCardSuccess');
export const freezeCardFailure = createAction('freezeCardFailure');

export const freezeCard = cardSerial => async dispatch => {
    dispatch(freezeCardRequest({ cardSerial }));

    try {
        await api.patch(`cards/${cardSerial}/freeze`);
        return dispatch(freezeCardSuccess());
    } catch (e) {
        return handleErrors(dispatch, () => freezeCard({ cardSerial }), e, true);
        // return dispatch(freezeCard({ cardSerial }));
    }
};

// defrost card
export const defrostCardRequest = createAction('defrostCardRequest');
export const defrostCardSuccess = createAction('defrostCardSuccess');
export const defrostCardFailure = createAction('defrostCardFailure');

export const defrostCard = cardSerial => async dispatch => {
    dispatch(defrostCardRequest({ cardSerial }));

    try {
        await api.patch(`cards/${cardSerial}/unfreeze`);
        return dispatch(defrostCardSuccess());
    } catch (e) {
        return handleErrors(dispatch, () => defrostCardFailure({ cardSerial }), e, true);
        // return dispatch(defrostCardFailure({ cardSerial }));
    }
};

// reissue card
export const reissueCardRequest = createAction('reissueCardRequest');
export const reissueCardSuccess = createAction('reissueCardSuccess');
export const reissueCardFailure = createAction('reissueCardFailure');

export const reissueCard = (cardSerial, prevStatus) => async dispatch => {
    dispatch(reissueCardRequest({ cardSerial }));

    try {
        await api.post(`cards/reissue`, {
            cardSerialOrig: cardSerial,
            reason: prevStatus,
        });

        dispatch(reissueCardSuccess());
        dispatch(fetchCards());
        return;
    } catch (e) {
        handleErrors(dispatch, () => reissueCardFailure({ cardSerial, prevStatus }), e, true);
        dispatch(fetchCards());
        return ;
        // return dispatch(reissueCardFailure({ cardSerial, prevStatus }));
    }
};

// issue card to replace lost, stolen, damaged card
export const issueCardRequest = createAction('issueCardRequest');
export const issueCardSuccess = createAction('issueCardSuccess');
export const issueCardFailure = createAction('issueCardFailure');

export const issueCard = cardSerial => async dispatch => {
    dispatch(issueCardRequest());

    try {
        await api.post(`cards/issue`, {
            cardSerialOrig: cardSerial,
        });

        dispatch(issueCardSuccess());
        dispatch(fetchCards());
        setTimeout(() => dispatch(fetchAccount()), 5000);
        return;
    } catch (e) {
        return handleErrors(dispatch, issueCardFailure, e, true);
    }
};

// end card
export const endCardRequest = createAction('endCardRequest');
export const endCardSuccess = createAction('endCardSuccess');
export const endCardFailure = createAction('endCardFailure');

export const endCard = (cardSerial, reason) => async dispatch => {
    dispatch(endCardRequest({ cardSerial, newStatus: convertReasonToStatus(reason) }));

    try {
        await api.patch(`cards/${cardSerial}/end?reason=${reason}`);
        return dispatch(endCardSuccess());
    } catch (e) {
        return handleErrors(dispatch, () => endCardFailure({ cardSerial }), e, true);
        // return dispatch(endCardFailure({ cardSerial }));
    }
};

// activate card
export const activateCardRequest = createAction('activateCardRequest');
export const activateCardSuccess = createAction('activateCardSuccess');
export const activateCardFailure = createAction('activateCardFailure');

export const activateCard = cardSerial => async dispatch => {
    dispatch(activateCardRequest({ cardSerial }));

    try {
        await api.patch(`cards/${cardSerial}/activate`);
        return dispatch(activateCardSuccess());
    } catch (e) {
        return handleErrors(dispatch, () => activateCardFailure({ cardSerial }), e, true);
        // return dispatch(activateCardFailure({ cardSerial }));
    }
};

// view pin
export const fetchPinRequest = createAction('fetchPinRequest');
export const fetchPinSuccess = createAction('fetchPinSuccess');
export const fetchPinFailure = createAction('fetchPinFailure');
export const clearPinData = createAction('clearPinData');

export const fetchPin = (cardSerial, CVC2) => async dispatch => {
    dispatch(fetchPinRequest());
    let uri;
    let token;

    try {
        const { data } = await api.get(`cards/${cardSerial}/pin`);
        const { data: payloadData } = data;

        token = payloadData.token;
        uri = payloadData.uri;
    } catch (e) {
        return handleErrors(dispatch, fetchPinFailure, e, true);
    }

    try {
        const customerCode = '192192019135';
        const postData = `customerCode=${customerCode}&cardSerial=${cardSerial}&token=${token}&CVC2=${CVC2}`;

        await getPinNumber(uri, postData).then(pinData => dispatch(fetchPinSuccess(pinData)));
    } catch(e) {
        const {errorMessage} = e.response.data;
        const error = cardPinErrors[errorMessage] || 'Internal system error';
        //!!!DON'T USE HANDLE ERRORS HERE!!!
        return dispatch(fetchPinFailure(error));
    }
};

const getPinNumber = async (uri, postData) => {
    try {
        const { data } = await axios.post(uri, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return data;
    } catch (error) {
        console.log({ error });
        throw error;
    }
};
