import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllFees,
    getFieldErrors,
    getFormError,
    getSelectedPayee,
    getTransactionsIsPosting,
} from 'lib/selectors';
import { useMakePayment } from 'lib/hooks';
import {
    PAYMENT_MAKE_PAYMENT,
    PAYMENT_MAKE_PAYMENT_SUCCESS,
} from 'constants/shared/routes';
import { hideModal, showModal } from 'actions/modal';
import {
    CONFIRM_AND_SEND_MODAL,
    ERROR_MODAL,
    PAYMENT_FEE_MODAL,
} from 'constants/shared/modalTypes';
import { dispatchHideModal } from 'utils/modals';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import MakePaymentForm from '../presentational/MakePaymentForm';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { usePrevious } from 'lib/utils';

const MakePaymentFormContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPayee = useSelector(getSelectedPayee);
    const allFees = useSelector(getAllFees);
    const { internalTransactionFee, externalTransactionFee } = allFees;

    const {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useMakePayment(onSubmit, onError);


    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);

    const prevState = usePrevious({fieldErrors, formError});

    const handleCloseModal = useCallback(() => {
        dispatch(hideModal());
    }, []);
    
    useEffect(() => {
        const isNewFormError = formError && !prevState.formError;
        const isNewFieldErrors = Object.keys(fieldErrors).length > Object.keys(prevState.fieldErrors).length;

        if (isNewFormError || isNewFieldErrors){
            handleCloseModal();
        }
    }, [fieldErrors, formError, prevState, handleCloseModal])

    const handleConfirmModal = () => {
        const fee = selectedPayee.isSuitsMeCustomer
            ? internalTransactionFee
            : externalTransactionFee;
        if (!form.amount || form.amount === '£0.00') {
            dispatch(
                showModal({
                    type: ERROR_MODAL,
                    props: {
                        title: 'Invalid amount',
                        message: 'Please enter an amount larger than £0.00',
                    },
                }),
            );
        } else if (selectedPayee.isSuitsMeCustomer) {
            handleShowConfirmAndSendModal();
        } else {
            dispatch(
                showModal({
                    type: PAYMENT_FEE_MODAL,
                    props: {
                        fee,
                        additionalCost: form.Amount * 100,
                        handleSubmit: handleShowConfirmAndSendModal,
                        closeModal: handleCloseModal,
                    },
                }),
            );
        }
    };

    const handleShowConfirmAndSendModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_AND_SEND_MODAL,
                props: {
                    form,
                    selectedPayee,
                    handleSubmit,
                    error,
                    postingSelector: getTransactionsIsPosting,
                    type: 'make-payment',
                },
            }),
        );
    };



    function onSubmit() {
        dispatchHideModal(dispatch);
        history.push(PAYMENT_MAKE_PAYMENT_SUCCESS, {
            data: {
                amount: form.amount,
                recipient: selectedPayee.payeeName,
            },
        });
    }

    function onError() {
        dispatchHideModal(dispatch);
        history.push(PAYMENT_MAKE_PAYMENT_SUCCESS, {
            data: {
                error,
            },
        });
    }

    useEffect(() => {
        if (!selectedPayee) {
            history.push(PAYMENT_MAKE_PAYMENT);
        }
    }, []);

    return (
        <PageContainer className="make-payment-form-container">
            <SectionTitle
                title={selectedPayee && selectedPayee.payeeName}
                backRoute={PAYMENT_MAKE_PAYMENT}
                additionalText={`${selectedPayee &&
                    selectedPayee.accountNo} | ${selectedPayee &&
                    selectedPayee.sortCode}`}
            />
            <MakePaymentForm
                {...form}
                handleChange={handleChange}
                handleSubmit={handleConfirmModal}
                isPosting={isPosting}
            />
        </PageContainer>
    );
};

export default MakePaymentFormContainer;
