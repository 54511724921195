import React from 'react';
import useTranslate from 'lib/languages/useTranslate';

const PaymentSwitch = ({ active, ukClick, intClick }) => {
    const translate = useTranslate('makePayment')

    return (
        <div className="payee-switch-container">
            <span onClick={ukClick} className={`switch ${!active && 'active'}`}>
                <h6>{translate('makePaymentTab1')}</h6>
            </span>
            <span onClick={intClick} className={`switch ${active && 'active'}`}>
                <h6>{translate('makePaymentTab2')}</h6>
            </span>
        </div>
    );
};

export default PaymentSwitch;
