import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import DirectDebitItem from '../presentational/DirectDebitItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDirectDebits,
    getDirectDebitsError,
    getDirectDebitsIsFetching,
    getDirectDebitsIsPosting,
} from 'lib/selectors';
import { hideModal, showModal } from 'actions/modal';
import { CONFIRM_MODAL, ERROR_MODAL } from 'constants/shared/modalTypes';
import { cancelDirectDebit, fetchDirectDebits } from 'lib/actions';
import { usePrevious } from 'lib/utils';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';

const DirectDebitItemContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const directDebits = useSelector(getDirectDebits);
    const isPosting = useSelector(getDirectDebitsIsPosting);
    const isFetching = useSelector(getDirectDebitsIsFetching);
    const error = useSelector(getDirectDebitsError);

    const prevProps = usePrevious({ isPosting, error });
    const directDebit = directDebits.find(directDebit => directDebit.id === id);

    useEffect(() => {
        dispatch(fetchDirectDebits());
    }, []);

    useEffect(() => {
        if (!isPosting && prevProps.isPosting) {
            if (error) {
                dispatch(showModal({ type: ERROR_MODAL }));
            } else {
                dispatch(hideModal({ message: error }));
            }
        }
    }, [isPosting]);

    const handleSubmitCancel = () => {
        dispatch(cancelDirectDebit(directDebit.id));
    };
    const showCancelModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    handleSubmit: handleSubmitCancel,
                    title: 'Cancel Direct Debit',
                    message:
                        'Are you sure you want to cancel this direct debit?',
                },
            }),
        );
    };
    if (!isFetching && !directDebit) {
        return <Redirect to="/payments/direct-debit" />;
    }
    return (
        <DataCheck
            isLoading={isFetching}
            data={directDebit}
            noDataMessage="You currently have no direct debits"
        >
            <DirectDebitItem
                data={directDebit}
                showCancelModal={showCancelModal}
            />
        </DataCheck>
    );
};

export default DirectDebitItemContainer;
