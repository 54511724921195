import React from 'react';
import FeesModal from '../feesModal/FeesModal';

function StandingOrderFeeModal({
    fee,
    recurranceFee,
    handleSubmit,
    closeModal,
}) {
    return (
        <FeesModal
            action="Setting up a standing order"
            fee={fee}
            standingOrderRecurranceFee={recurranceFee}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
        />
    );
}

export default StandingOrderFeeModal;
