import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import TextInput from 'components/shared/form/presentational/TextInput';
import MaskInput from 'components/shared/form/presentational/MaskInput';
import useTranslate from 'lib/languages/useTranslate';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const CreatePayee = ({
    formData: { payeeName, sortCode, payeeAccountNo, password },
    handleChange,
    handleSubmit,
    error,
    isPosting,
}) => {
    const translate = useTranslate('addPayee');
    return (
        <Form
            submitText={translate('submitText')}
            onSubmit={handleSubmit}
            isPosting={isPosting}
            error={error ? 'Something went wrong! Please try again.' : ''}
        >
            <Field required>
                <TextInput
                    classes="generic-input bg"
                    required
                    name="payeeName"
                    value={payeeName}
                    placeholder={translate('name')}
                    onChange={handleChange}
                    minLength={6}
                />
            </Field>
            <Field required>
                <MaskInput
                    required
                    placeholder={translate('accountNumber')}
                    onChange={handleChange}
                    name="payeeAccountNo"
                    value={payeeAccountNo}
                    classes="generic-input bg"
                    mask="9999 9999"
                />
            </Field>
            <Field required>
                <MaskInput
                    required
                    placeholder={translate('sortCode')}
                    onChange={handleChange}
                    name="sortCode"
                    value={sortCode}
                    classes="generic-input bg"
                    mask="99-99-99"
                />
            </Field>

            <Field required>
                <TextInput
                    required
                    placeholder={translate('re-enterPassword')}
                    onChange={handleChange}
                    name="password"
                    value={password}
                    type="password"
                    classes="generic-input"
                    minLength={10}
                />
            </Field>
            <Typography className="warning">
                {translate('warningMessage')}
            </Typography>
        </Form>
    );
};

export default CreatePayee;
