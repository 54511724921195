export const getZendeskCategories = state => Object.values(state.zendeskReducer.categories);
export const getZendeskCategory = (state, id) => state.zendeskReducer.categories[id] || {};
export const getZendeskArticles = (state, categoryID) =>
    Object.values(state.zendeskReducer.articles).filter(
        item => '' + item.categoryID === '' + categoryID,
    );

export const getZendeskArticle = (state, id) => state.zendeskReducer.articles[id] || {};
export const getZendeskIsFetching = state => state.zendeskReducer.isFetching;
export const getZendeskError = state => state.zendeskReducer.error;
