import { createReducer } from '@reduxjs/toolkit';
import {
    twCreateRecipientHandleStaticFormChange,
    twCreateRecipientHandleDynamicFormChange,
    twCreateRecipientRemoveDynamicField,
    twCreateRecipientResetForm,
} from '../actions/tw-recipientsCreate';

const initialStaticData = {
    accountHolderName: '',
    email: '',
    type: null,
};

const initialState = {
    formStaticData: initialStaticData,
    formDynamicData: {},
};

export default createReducer(initialState, {
    [twCreateRecipientHandleStaticFormChange]: handleStaticFormChange,
    [twCreateRecipientHandleDynamicFormChange]: handleDynamicFormChange,
    [twCreateRecipientRemoveDynamicField]: handleRemoveDynamicField,
    [twCreateRecipientResetForm]: handleResetForm,
});

function handleStaticFormChange(state, action) {
    state.formStaticData[action.payload.name] = action.payload.value;
}

function handleDynamicFormChange(state, action) {
    state.formDynamicData[action.payload.name] = action.payload.value;
}

function handleRemoveDynamicField(state, action) {
    delete state.formDynamicData[action.payload];
}

function handleResetForm(state) {
    state.formStaticData = initialStaticData;
    state.formDynamicData = {};
}
