import { hideModal, showModal } from 'actions/modal';
import { ACCOUNT_BLOCKED_MODAL } from 'constants/shared/modalTypes';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    hideAccountBlockedModal,
    showAccountBlockedModal,
} from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import { getShowAccountBlockedModal } from 'lib/selectors';
import { usePrevious } from 'lib/utils';
import { dispatchHideModal } from 'utils/modals';

const useIsPpsAccountBlocked = () => {
    const dispatch = useDispatch();
    const translate = useTranslate('accountBlocked');
    const shouldShowAccountBlockedModal = useSelector(
        getShowAccountBlockedModal,
    );

    let isBlocked = false;
    try {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        if (
            !!decoded &&
            (decoded.IsPpsAccountBlocked === true ||
                decoded.IsPpsAccountBlocked === 'true')
        )
            isBlocked = true;
    } catch (e) {
        console.log(e);
    }

    const prevShouldShow = usePrevious(shouldShowAccountBlockedModal);

    const showBlockedModal = () => {
        dispatch(showAccountBlockedModal());
        dispatch(
            showModal({
                type: ACCOUNT_BLOCKED_MODAL,
                props: {
                    closeModal: () => dispatchHideModal(dispatch),
                },
            }),
        );
    };

    const hideBlockedModal = () => {
        dispatch(hideAccountBlockedModal());
        dispatch(hideModal());
    };

    useEffect(() => {
        if (shouldShowAccountBlockedModal && !prevShouldShow) {
            console.log('show modal');
            dispatch(
                showModal({
                    type: ACCOUNT_BLOCKED_MODAL,
                }),
            );
        } else if (prevShouldShow && !shouldShowAccountBlockedModal) {
            console.log('hide modal');
            dispatch(hideModal());
        }
    }, [isBlocked, prevShouldShow]);

    return {
        isBlocked,
        hideAccountBlockedModal: hideBlockedModal,
        showAccountBlockedModal: showBlockedModal,
    };
};

export default useIsPpsAccountBlocked;
