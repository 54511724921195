import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors, setJwtHeader } from '../utils';
import { fetchCards } from './cards';
import { fetchPayees } from './payees';
import { fetchActivity } from './transactions';

// Fetch account
export const fetchAccountRequest = createAction('FETCH_ACCOUNT_REQUEST');
export const fetchAccountSuccess = createAction('FETCH_ACCOUNT_SUCCESS');
export const fetchAccountFailure = createAction('FETCH_ACCOUNT_FAILURE');

export const fetchAccount = () => async dispatch => {
    dispatch(fetchAccountRequest());

    try {
        const { data } = await api.get('account');
        const { cards, ...account } = data;
        return dispatch(fetchAccountSuccess({ account, cards }));
    } catch (e) {
        return handleErrors(dispatch, fetchAccountFailure, e, true);
    }
};

// Create New Card Holder
export const createCardHolderRequest = createAction('CREATE_CARD_HOLDER_REQUEST');
export const createCardHolderSuccess = createAction('CREATE_CARD_HOLDER_SUCCESS');
export const createCardHolderFailure = createAction('CREATE_CARD_HOLDER_FAILURE');

export const createCardHolder = postBody => async dispatch => {
    dispatch(createCardHolderRequest());

    try {
        await api.post(`account/card-holder`, postBody);
        dispatch(fetchCards());
        return dispatch(createCardHolderSuccess());
    } catch (e) {
        console.log(e);
        return handleErrors(dispatch, createCardHolderFailure, e, true);
    }
};

// Regrade account
export const regradeAccountRequest = createAction('regradeAccountRequest');
export const regradeAccountSuccess = createAction('regradeAccountSuccess');
export const regradeAccountFailure = createAction('regradeAccountFailure');

export const regradeAccount = postBody => async dispatch => {
    dispatch(regradeAccountRequest());

    try {
        await api.post(`account/regrade`, postBody);
        dispatch(fetchCards());
        return dispatch(regradeAccountSuccess());
    } catch (e) {
        console.log(e);
        return handleErrors(dispatch, regradeAccountFailure, e, true);
    }
};

//Update password

export const putUpdatePasswordRequest = createAction('putUpdatePasswordRequest');
export const putUpdatePasswordSuccess = createAction('putUpdatePasswordSuccess');
export const putUpdatePasswordFailure = createAction('putUpdatePasswordFailure');

export const updatePassword = postBody => async dispatch => {
    dispatch(putUpdatePasswordRequest());

    try {
        await api.put('account/password', postBody);
        dispatch(putUpdatePasswordSuccess());
    } catch (e) {
        handleErrors(dispatch, putUpdatePasswordFailure, e, true);
    }
};

// Fetch multiple accounts for user
export const fetchAccountsListRequest = createAction('fetchAccountsListRequest');
export const fetchAccountsListSuccess = createAction('fetchAccountsListSuccess');
export const fetchAccountsListFailure = createAction('fetchAccountsListFailure');

export const fetchAccountsList = () => async dispatch => {
    dispatch(fetchAccountsListRequest());

    try {
        const { data } = await api.get('account/list');

        dispatch(fetchAccountsListSuccess(data));
    } catch (e) {
        handleErrors(dispatch, fetchAccountsListFailure, e, true);
    }
};

export const updateSelectedAccountRequest = createAction('updateSelectedAccountRequest');
export const updateSelectedAccountSuccess = createAction('updateSelectedAccountSuccess');
export const updateSelectedAccountFailure = createAction('updateSelectedAccountFailure');

export const updateSelectedAccount = ppsAccountNo => async dispatch => {
    dispatch(updateSelectedAccountRequest());

    try {
        const { data } = await api.patch(`account/select?ppsAccountNumber=${ppsAccountNo}`);
        if (data.token) setJwtHeader(data.token);

        dispatch(updateSelectedAccountSuccess(data));
    } catch (e) {
        handleErrors(dispatch, updateSelectedAccountFailure, e, true);
    }
};

export const showAccountBlockedModal = createAction('setShowAccountBlockedModal');
export const hideAccountBlockedModal = createAction('setHideAccountBlockedModal');
