import React from 'react';
import { useSelector } from 'react-redux';

import {
    getAccount,
    getAccountIsFetching,
    getAccountError,
} from 'lib/selectors';
import { allCurrencies } from 'lib/constants';
import { MORE } from 'constants/shared/routes';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import AccountDetails from '../presentational/AccountDetails';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';

const AccountDetailsContainer = () => {
    const translate = useTranslate('accountDetails');
    const account = useSelector(getAccount);
    const isFetching = useSelector(getAccountIsFetching);
    const error = useSelector(getAccountError);

    console.log(getCurrencyFlag());

    return (
        <PageContainer className="more-account-details-container">
            <SectionTitle title={translate('titleText')} backRoute={MORE} />
            <DataCheck
                data={account}
                isLoading={isFetching}
                error={error}
                renderIfNotEmpty
                noDataMessage={translate('noDataMessage')}
            >
                <AccountDetails data={account} flag={getCurrencyFlag()} />
            </DataCheck>
        </PageContainer>
    );

    function getCurrencyFlag() {
        return allCurrencies.filter(
            currency =>
                account.clearedBalanceCurrencyCode === currency.currency_code,
        )[0];
    }
};

export default AccountDetailsContainer;
