import React from 'react';
import useTranslate from 'lib/languages/useTranslate';

import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import { SUITS_ME_CASHBACK_REWARDS } from 'constants/shared/routes';

const CashbackRewards = () => {
    const translate = useTranslate('cashbackRewards');

    return (
        <PageContainer className="more-cashback-rewards-container">
            <SectionTitle title="Cashback Rewards" />
            <div className="cashback-rewards-container">
                <h1 className="title">{translate('breadcrumb')}</h1>
                <h2 className="headline">
                    {translate('title')}
                </h2>

                <p className="text">{translate('paragraphOne')}</p>

                <p className="text">{translate('paragraphTwo')}</p>
            </div>
            <ButtonContainer>
                <Button href={SUITS_ME_CASHBACK_REWARDS}>See More</Button>
            </ButtonContainer>
        </PageContainer>
    );
};

export default CashbackRewards;
