import React from 'react';

import Field from 'components/shared/form/presentational/Field';
import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Form from 'components/shared/form/presentational/Form';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import FieldLink from 'components/shared/form/presentational/FieldLink';
import useTranslate from 'lib/languages/useTranslate';
import CodeInput from 'components/shared/form/presentational/CodeInput';

const EnterCVCModal = ({ handleChange, handleSubmit, isPosting, error }) => {
    const translate = useTranslate('cards.enterCVCForm');

    let errorMessage = null;

    if (error && error.includes('403'))
        errorMessage = translate('403ErrorMessage');
    else if (errorMessage) translate('errorMessage');

    return (
        <ModalOuterContainer
            className="enter-cvc-modal"
            isPosting={isPosting}
            handleSubmit={handleSubmit}
        >
            <div className="description">
                <Typography tag="h1" className="">
                    {translate('title')}
                </Typography>
                <Typography styles={{ marginBottom: '8px' }}>
                    {translate('description')}
                </Typography>
            </div>
            <Form
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={errorMessage}
                omitButtons
            >
                <Field required name="CVC" classes="left">
                    <CodeInput
                        name="cvc"
                        width={38}
                        height={52}
                        fields={3}
                        onChange={handleChange}
                    />
                </Field>
                <FieldLink className="cvc-code">
                    {translate('infoText')}
                </FieldLink>
            </Form>
        </ModalOuterContainer>
    );
};

export default EnterCVCModal;
