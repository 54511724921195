import { createAction } from '@reduxjs/toolkit';
import { getTransactionPageNumber } from '../selectors/transactions';

import { api, handleErrors } from '../utils';
import { fetchAccount } from './account';

//Fetch Tw and PPS transactions.
export const fetchActivityRequest = createAction('FETCH_ACTIVITY_REQUEST');
export const fetchActivitySuccess = createAction('FETCH_ACTIVITY_SUCCESS');
export const fetchActivityFailure = createAction('FETCH_ACTIVITY_FAILURE');

export const fetchActivity =
    (isFirstPage, limit = 10) =>
    async (dispatch, getState) => {
        dispatch(fetchActivityRequest());

        const pageNumber = getTransactionPageNumber(getState());

        try {
            const params = { limit, pageNumber: isFirstPage ? 1 : pageNumber + 1 };

            const urlParams = `pageNumber=${params.pageNumber}&limit=${params.limit}`;

            const { data } = await api.get(`activity?${urlParams}`);

            const { isLastPage, nextPageStartDate, items } = data;

            const payload = {
                isLastPage,
                nextPageStartDate,
                items,
                isFirstPage: isFirstPage,
            };

            return dispatch(fetchActivitySuccess(payload));
        } catch (e) {
            return handleErrors(dispatch, fetchActivityFailure, e, true);
        }
    };

export const createTransactionRequest = createAction('CREATE_TRANSACTION_REQUEST');
export const createTransactionSuccess = createAction('CREATE_TRANSACTION_SUCCESS');
export const createTransactionFailure = createAction('CREATE_TRANSACTION_FAILURE');

export const createTransaction = postBody => async dispatch => {
    dispatch(createTransactionRequest());

    try {
        await api.post('transactions/v2/MakePayment', postBody);
        dispatch(createTransactionSuccess());
        dispatch(fetchAccount());
        setTimeout(() => {
            dispatch(fetchActivity(true));
        }, 150);
    } catch (e) {
        return handleErrors(dispatch, createTransactionFailure, e);
    }
};

export const fetchPendingTransactionsRequest = createAction('FETCH_PENDING_TRANSACTIONS_REQUEST');
export const fetchPendingTransactionsSuccess = createAction('FETCH_PENDING_TRANSACTIONS_SUCCESS');
export const fetchPendingTransactionsFailure = createAction('FETCH_PENDING_TRANSACTIONS_FAILURE');

export const fetchPendingTransactions = () => async (dispatch, getState) => {
    dispatch(fetchPendingTransactionsRequest());

    try {
        const { data } = await api.get('activity/pending');

        return dispatch(fetchPendingTransactionsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchPendingTransactionsFailure, e, true);
    }
};
