import { createAction } from '@reduxjs/toolkit';

export const twCreateRecipientHandleStaticFormChange = createAction(
    'TW_CREATE_RECIPIENT_HANDLE_STATIC_FORM_CHANGE',
);
export const twCreateRecipientHandleDynamicFormChange = createAction(
    'TW_CREATE_RECIPIENT_HANDLE_DYNAMIC_FORM_CHANGE',
);
export const twCreateRecipientRemoveDynamicField = createAction(
    'TW_CREATE_RECIPIENT_REMOVE_DYNAMIC_FIELD',
);
export const twCreateRecipientResetForm = createAction('TW_CREATE_RECIPIENT_RESET_FORM');
