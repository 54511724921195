import { createReducer } from '@reduxjs/toolkit';

import { fetchFeesFailure, fetchFeesRequest, fetchFeesSuccess } from '../actions';

const initialState = {
    fees: {
        additionalCardholder: null,
        replacementCard: null,
        externalTransactionFee: null,
        internalTransactionFee: null,
        standingOrderSetupFee: null,
    },
    isFetching: false,
    error: null,
};

export default createReducer(initialState, {
    [fetchFeesRequest]: handleFetchRequest,
    [fetchFeesSuccess]: handleFetchSuccess,
    [fetchFeesFailure]: handleFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.fees = action.payload;
    state.isFetching = false;
}

function handleFailure(state, action) {
    state.error = action.payload;
    state.isFetching = false;
}
