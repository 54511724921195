import React from 'react';
import { useSelector } from 'react-redux';
import useTranslate from 'lib/languages/useTranslate';

import { getAccount } from 'lib/selectors';
import { formatMoney } from 'lib/utils';
import Button from '../../generic/button/presentational/Button';
import Typography from '../../generic/typography/presentational/Typography';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import { accountRolesLookup } from 'lib/constants/account';

const FeesModal = ({
    action,
    fee,
    standingOrderRecurranceFee,
    additionalCost = 0,
    handleSubmit,
    closeModal,
}) => {
    const { availableBalance, productType } = useSelector(getAccount);
    const translate = useTranslate('feesModal');

    const getTextToDisplay = () => {
        if(standingOrderRecurranceFee === null || typeof standingOrderRecurranceFee === 'undefined') {
            return translate('promptText', {
                action: action,
                fee: formatMoney(fee),
            })
        } else {
            if(fee === 0) {
                return  translate('freeStandingOrderPromptText', {
                    action: action,
                    standingOrderFee: formatMoney(
                        standingOrderRecurranceFee,
                    ),
                    fee: formatMoney(fee),
                    accountType: accountRolesLookup[productType]
                })
            } else {
                return  translate('standingOrderPromptText', {
                    action: action,
                    standingOrderFee: formatMoney(
                        standingOrderRecurranceFee,
                    ),
                    fee: formatMoney(fee),
                })}
            }
        }

    return (
        <ModalOuterContainer
            className="add-cardholder-fee-modal"
            handleSubmit={handleSubmit}
            hideModal={closeModal}
            isPosting={false}
            customButtons={availableBalance < fee + additionalCost}
        >
            {availableBalance < fee + additionalCost ? (
                <>
                    <Typography tag="h1">
                        {translate('insufficientFunds')}
                    </Typography>
                    <Typography className="description">
                        {translate('noFunds')}
                    </Typography>
                    <Button type="submit" onClick={closeModal}>
                        {translate('ok')}
                    </Button>
                </>
            ) : (
                <>
                    <Typography tag="h1">{translate('newFee')}</Typography>
                    <Typography className="description">
                        {getTextToDisplay()}
                    </Typography>
                </>
            )}
        </ModalOuterContainer>
    );
};

export default FeesModal;
