import { createReducer } from '@reduxjs/toolkit';
import { twSetAccountState, twFetchAccountStatusRequest, twFetchAccountStatusFailure } from '../actions';
import { TW_ACCOUNT_STATES } from '../constants';

const initialState = {
    accountState: TW_ACCOUNT_STATES.UNKNOWN,
    isFetching: false,
    error: null,
};

export default createReducer(initialState, {
    [twSetAccountState]: handleSetAccountState,
    [twFetchAccountStatusRequest]: handleFetchRequest,
    [twFetchAccountStatusFailure]: handleFetchFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleSetAccountState(state, action) {
    state.isFetching = false;
    state.accountState = action.payload;
}

