import { createAction } from '@reduxjs/toolkit';
import { getZendeskLanguageCode } from '../selectors/languages';

import { api, handleErrors } from '../utils';

// Fetch personal details
export const fetchZendeskCategoriesRequest = createAction('fetchZendeskCategoriesRequest');
export const fetchZendeskCategoriesSuccess = createAction('fetchZendeskCategoriesSuccess');
export const fetchZendeskCategoriesFailure = createAction('fetchZendeskCategoriesFailure');

export const fetchZendeskCategories = () => async (dispatch, getState) => {
    dispatch(fetchZendeskCategoriesRequest());

    const language = getZendeskLanguageCode(getState());
    try {
        const { data } = await api.get(`faq/categories?language=${language}`);
        return dispatch(fetchZendeskCategoriesSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchZendeskCategoriesFailure, e);
    }
};

// Fetch articles
export const fetchZendeskArticlesRequest = createAction('fetchZendeskArticlesRequest');
export const fetchZendeskArticlesSuccess = createAction('fetchZendeskArticlesSuccess');
export const fetchZendeskArticlesFailure = createAction('fetchZendeskArticlesFailure');

export const fetchZendeskArticles = categoryID => async (dispatch, getState) => {
    dispatch(fetchZendeskArticlesRequest());

    const language = getZendeskLanguageCode(getState());
    try {
        const { data } = await api.get(`faq/${categoryID}?language=${language}`);
        return dispatch(fetchZendeskArticlesSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchZendeskArticlesFailure, e);
    }
};
