import { showModal } from 'actions/modal';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from 'components/shared/modals/generic/containers/ModalOuterContainer';
import { ERROR_MODAL } from 'constants/shared/modalTypes';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatMoney, usePrevious } from 'lib/utils';
import { useRegradeSubscription } from 'lib/hooks';
import { fetchAccount, fetchPricePlans } from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import { accountRolesLookup } from 'lib/constants';
import { getLanguage } from 'lib/selectors';

const RegradeSubscriptionModal = ({ newRole, currentRole, hideModal }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        isPosting,
        postSuccess,
        error,
        isUpgrade,
        newCost,
        currentCost,
    } = useRegradeSubscription(currentRole, newRole);

    const translate = useTranslate('pricePlan');
    const currentPricePlanName = accountRolesLookup[currentRole];
    const newPricePlanName = accountRolesLookup[newRole];
    const languageCode = useSelector(getLanguage);

    const prevPostSuccess = usePrevious(postSuccess);
    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            dispatch(fetchAccount());
            dispatch(fetchPricePlans(languageCode));
            hideModal();
        }
    }, [postSuccess, prevPostSuccess, hideModal]);

    const prevError = usePrevious(error);

    useEffect(() => {
        if (!prevError && error) {
            dispatch(showModal({ type: ERROR_MODAL }));
        }
    }, [error, dispatch]);

    return (
        <ModalOuterContainer handleSubmit={handleSubmit} isPosting={isPosting}>
            <div className="description">
                <Typography tag="h1">
                    {translate('confirmModal.titleText')}
                </Typography>
                <Typography>
                    {translate(
                        isUpgrade
                            ? 'confirmModal.upgradeDescription'
                            : 'confirmModal.downgradeDescription',
                        {
                            currentCost: `£${formatMoney(currentCost)}`,
                            newCost: `£${formatMoney(newCost)}`,
                            currentPricePlanName,
                            newPricePlanName,
                        },
                    )}
                </Typography>
            </div>
        </ModalOuterContainer>
    );
};

export default RegradeSubscriptionModal;
