import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTransaction } from '../actions';
import { useForm, usePrevious } from '../utils';
import {
    getTransactionsIsPosting,
    getTransactionsPostSuccess,
    getTransactionsPostError,
    getSelectedPayeeId,
} from '../selectors';

export default (onSubmit, onError) => {
    const dispatch = useDispatch();
    const success = useSelector(getTransactionsPostSuccess);
    const isPosting = useSelector(getTransactionsIsPosting);
    const error = useSelector(getTransactionsPostError);
    const selectedPayeeId = useSelector(getSelectedPayeeId);
    const [form, handleChange] = useForm({ amount: '', paymentReference: '', password: '' });

    const prevProps = usePrevious({ success, isPosting });

    useEffect(() => {
        if (!prevProps.success && success && onSubmit) {
            onSubmit();
        }

        if (prevProps.isPosting && error && onError) {
            onError();
        }
        //eslint-disable-next-line
    }, [isPosting]);

    const handleSubmit = () => {
        const postBody = {
            ...form,
            amount: form.amount.toString().includes('£')
                ? parseInt((form.amount.substring(1) * 100).toFixed(0))
                : parseInt((form.amount * 100).toFixed(0)),
            PayeeID: selectedPayeeId,
        };
        dispatch(createTransaction(postBody));
    };

    return {
        form,
        isPosting,
        handleChange,
        handleSubmit,
        error,
    };
};
