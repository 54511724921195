import React from 'react';
import ReactCurrencyInput from 'react-currency-input';
import withFieldValidation from '../hocs/withFieldValidation';

const CurrencyInput = ({
    name,
    placeholder,
    value,
    onChange,
    disabled,
    prefix,
    classes = '',
    required = false,
}) => {
    return (
        <ReactCurrencyInput
            required={required}
            className={`input-currency ${classes}`}
            type="text"
            placeholder={placeholder}
            value={value}
            thousandSeparator=""
            prefix={prefix}
            onChangeEvent={(e, maskedValue, floatValue) => {
                if (disabled) return;
                onChange(name, floatValue);
            }}
        />
    );
};

export default withFieldValidation(CurrencyInput);
