import { createReducer } from '@reduxjs/toolkit';

import {
    addFieldError,
    removeFieldError,
    clearFieldErrors,
    setErrorsVisible,
    setFieldErrors,
    addGenericFormError,
    clearFormError,
} from '../actions';

const initialState = {
    fieldErrors: {},
    formError: null,
    errorsVisible: false,
};

export default createReducer(initialState, {
    [addFieldError]: handleAddError,
    [addGenericFormError]: handleAddFormError,
    [clearFormError]: handleClearFormError,
    [removeFieldError]: handleRemoveError,
    [clearFieldErrors]: handleClearErrors,
    [setFieldErrors]: handleSetErrors,
    [setErrorsVisible]: handleSetErrorsVisible,
});

function handleAddError(state, action) {
    state.fieldErrors[action.payload.name] = action.payload.value;
}

function handleClearFormError(state) {
    state.formError = null;
}

function handleAddFormError(state, action) {
    state.formError = action.payload;
}

function handleRemoveError(state, action) {
    delete state.fieldErrors[action.payload];
}

function handleSetErrors(state, action) {
    state.errorsVisible = true;
    state.fieldErrors = action.payload;
}

function handleClearErrors(state) {
    state.fieldErrors = {};
}

function handleSetErrorsVisible(state, action) {
    state.errorsVisible = action.payload;
}
