import React from 'react';

import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CommunicationPreferences from '../presentational/CommunicationPreferences';

import useCommunicationPreferences from 'utils/hooks/useCommunicationPreferences';
import useTranslate from 'lib/languages/useTranslate';

import LoadingComponent from 'components/shared/generic/loader/LoadingComponent';

const CommunicationPreferencesContainer = () => {
    const {
        comms,
        isFetching,
        postError,
        handleSwitchClick,
    } = useCommunicationPreferences();
    const translate = useTranslate('commsPreferences');

    if (isFetching && !Object.keys(comms).length) return <LoadingComponent />;

    return (
        <PageContainer className="more-communication-preferences-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={MORE}
            />

            <CommunicationPreferences
                {...comms}
                handleClick={handleSwitchClick}
                postError={postError}
            />
        </PageContainer>
    );
};

export default CommunicationPreferencesContainer;
