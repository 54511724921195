import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'lib/actions';
import More from '../presentational/More';

import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import { showModal } from 'actions/modal';
import { EXPORT_STATEMENT_MODAL } from 'constants/shared/modalTypes';

const MoreContainer = () => {
    const dispatch = useDispatch();
    const isDarkModeEnabled = useSelector(getTheme);
    const handleShowExportModal = () => {
        // todo
        dispatch(showModal({ type: EXPORT_STATEMENT_MODAL, props: {} }));
    };

    const handleLogoutClick = () => {
        dispatch(logout());
    };

    return (
        <More
            logoutClick={handleLogoutClick}
            isDarkModeEnabled={isDarkModeEnabled}
            handleShowExportModal={handleShowExportModal}
        />
    );
};

export default MoreContainer;
