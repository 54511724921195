import React from 'react';
import FeesModal from '../feesModal/FeesModal';

function AddCardHolderFeeModal({ fee, handleSubmit, closeModal }) {
    return (
        <FeesModal
            action="Adding an additional cardholder"
            fee={fee}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
        />
    );
}

export default AddCardHolderFeeModal;
