export const standingOrdersRecurringValues = {
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    YEARLY: 4,
};

export const standingOrdersRecurringNames = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
    4: 'Yearly',
};
