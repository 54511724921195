import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

// Select payee
export const selectPayee = createAction('SELECT_PAYEE');

// Fetch payees
export const fetchPayeesRequest = createAction('FETCH_PAYEES_REQUEST');
export const fetchPayeesSuccess = createAction('FETCH_PAYEES_SUCCESS');
export const fetchPayeesFailure = createAction('FETCH_PAYEES_FAILURE');

export const fetchPayees = () => async dispatch => {
    dispatch(fetchPayeesRequest());

    try {
        const { data } = await api.get('payee');
        return dispatch(fetchPayeesSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchPayeesFailure, e, true);
    }
};

// Create payee
export const createPayeeRequest = createAction('CREATE_PAYEE_REQUEST');
export const createPayeeSuccess = createAction('CREATE_PAYEE_SUCCESS');
export const createPayeeFailure = createAction('CREATE_PAYEE_FAILURE');

export const createPayee = postBody => async dispatch => {
    dispatch(createPayeeRequest());

    try {
        const { data } = await api.post('payee', postBody);
        return dispatch(createPayeeSuccess(data));
    } catch (e) {
        if (e.response.data.errorMessage) {
            return dispatch(createPayeeFailure(e.response.data.errorMessage));
        } else {
            return handleErrors(dispatch, createPayeeFailure, e);
        }
    }
};

// Delete payee
export const deletePayeeRequest = createAction('DELETE_PAYEE_REQUEST');
export const deletePayeeSuccess = createAction('DELETE_PAYEE_SUCCESS');
export const deletePayeeFailure = createAction('DELETE_PAYEE_FAILURE');

export const deletePayee = payee => async dispatch => {
    const payeeId = payee.id;

    dispatch(deletePayeeRequest({ payeeId }));

    try {
        await api.delete(`payee/${payeeId}`);
        return dispatch(deletePayeeSuccess());
    } catch (e) {
        return dispatch(deletePayeeFailure({ payee }));
    }
};
