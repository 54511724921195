import { createReducer } from '@reduxjs/toolkit';

import {
    fetchPayeesRequest,
    fetchPayeesSuccess,
    fetchPayeesFailure,
    createPayeeRequest,
    createPayeeSuccess,
    createPayeeFailure,
    deletePayeeRequest,
    deletePayeeSuccess,
    deletePayeeFailure,
    selectPayee,
    updateSelectedAccountSuccess,
} from '../actions';

import { convertArrToObj } from '../utils';

const initialState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    postError: null,
    payees: {},
    selectedPayeeId: null,
    last: false,
};

export default createReducer(initialState, {
    [fetchPayeesRequest]: handleFetchRequest,
    [fetchPayeesSuccess]: handleFetchSuccess,
    [fetchPayeesFailure]: handleFetchFailure,
    [createPayeeRequest]: handlePostRequest,
    [createPayeeFailure]: handlePostFailure,
    [createPayeeSuccess]: handlePostSuccess,
    [deletePayeeRequest]: handleDeleteRequest,
    [deletePayeeSuccess]: handleDeleteSuccess,
    [deletePayeeFailure]: handleDeleteFailure,
    [selectPayee]: handleSelectPayee,
    [updateSelectedAccountSuccess]: state => {
        state.payees = {};
        state.selectPayeeId = null;
    },
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state, action) {
    state.isFetching = false;

    state.payees = convertArrToObj(action.payload);
    state.last = true;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
    state.selectedPayeeId = null;
}

function handlePostSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.payees[action.payload.id] = action.payload;
    state.selectedPayeeId = action.payload.id;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleDeleteRequest(state, action) {
    state.isPosting = true;
    state.postError = null;
    delete state.payees[action.payload.payeeId];
}

function handleDeleteSuccess(state) {
    state.isPosting = false;
}

function handleDeleteFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
    state.payees[action.payload.payee.payeeId] = action.payload.payee;
}

function handleSelectPayee(state, action) {
    state.selectedPayeeId = action.payload;
}
