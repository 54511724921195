import { createAction } from '@reduxjs/toolkit';

import { api, handleErrors } from '../utils';

// Create standing order
export const createStandingOrderRequest = createAction('CREATE_STANDING_ORDER_REQUEST');
export const createStandingOrderSuccess = createAction('CREATE_STANDING_ORDER_SUCCESS');
export const createStandingOrderFailure = createAction('CREATE_STANDING_ORDER_FAILURE');

export const createStandingOrder = postBody => async dispatch => {
    dispatch(createStandingOrderRequest());

    try {
        const { data } = await api.post('standingorders/v2', postBody);
        return dispatch(createStandingOrderSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, createStandingOrderFailure, e);
    }
};

export const fetchStandingOrdersRequest = createAction('FETCH_STANDING_ORDERS_REQUEST');
export const fetchStandingOrdersSuccess = createAction('FETCH_STANDING_ORDERS_SUCCESS');
export const fetchStandingOrdersFailure = createAction('FETCH_STANDING_ORDERS_FAILURE');

export const fetchStandingOrders = () => async dispatch => {
    dispatch(fetchStandingOrdersRequest());

    try {
        const { data } = await api.get('standingorders');
        return dispatch(fetchStandingOrdersSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchStandingOrdersFailure, e, true);
    }
};

export const fetchSingleStandingOrderRequest = createAction('FETCH_SINGLE_STANDING_ORDER_REQUEST');
export const fetchSingleStandingOrderSuccess = createAction('FETCH_SINGLE_STANDING_ORDER_SUCCESS');
export const fetchSingleStandingOrderFailure = createAction('FETCH_SINGLE_STANDING_ORDER_FAILURE');

export const fetchSingleStandingOrder = postBody => async dispatch => {
    dispatch(fetchSingleStandingOrderRequest());

    try {
        const { data } = await api.post('standingorders', postBody);
        return dispatch(fetchSingleStandingOrderSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchSingleStandingOrderFailure, e, true);
    }
};

export const updateStandingOrderRequest = createAction('UPDATE_STANDING_ORDER_REQUEST');
export const updateStandingOrderSuccess = createAction('UPDATE_STANDING_ORDER_SUCCESS');
export const updateStandingOrderFailure = createAction('UPDATE_STANDING_ORDER_FAILURE');

export const updateStandingOrder = (id, postBody) => async dispatch => {
    dispatch(updateStandingOrderRequest());

    try {
        const { data } = await api.update(`standingorders/${id}`, postBody);
        return dispatch(updateStandingOrderSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, updateStandingOrderFailure, e);
    }
};

export const deleteStandingOrderRequest = createAction('DELETE_STANDING_ORDER_REQUEST');
export const deleteStandingOrderSuccess = createAction('DELETE_STANDING_ORDER_SUCCESS');
export const deleteStandingOrderFailure = createAction('DELETE_STANDING_ORDER_FAILURE');

export const deleteStandingOrder = id => async dispatch => {
    dispatch(deleteStandingOrderRequest());

    try {
        await api.delete(`standingorders/${id}`);
        return dispatch(deleteStandingOrderSuccess({ id }));
    } catch (e) {
        return handleErrors(dispatch, deleteStandingOrderFailure, e, true);
    }
};
