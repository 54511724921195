import { createReducer } from '@reduxjs/toolkit';
import { changeAddFormValue } from '../actions/addCardHolder';

const initialState = {
    addCardHolderForm: {
        title: '',
        forename: '',
        surname: '',
        email: '',
        dob: '',
        tel1: '',
        tel2: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        country: '',
        postcode: '',
    },
};

export default createReducer(initialState, {
    [changeAddFormValue]: handleChange,
});

function handleChange(state, action) {
    const { name, value } = action.payload;
    state.addCardHolderForm[name] = value;
}
