export const cardSatusNames = {
    I: 'Issued',
    A: 'Active',
    L: 'Lost',
    S: 'Stolen',
    D: 'Damaged',
    E: 'Expired',
    B: 'Blocked',
};
export const cardSatusValues = {
    ISSUED: 'I',
    ACTIVE: 'A',
    LOST: 'L',
    STOLEN: 'S',
    DAMAGED: 'D',
    EXPIRED: 'E',
    BLOCKED: 'B',
};

export const cardReissueReasonNames = {
    LOST: 'Lost',
    DAMAGED: 'Damaged',
    STOLEN: 'Stolen',
};

export const cardReissueReasonValues = {
    LOST: 'LOST',
    DAMAGED: 'DAMAGED',
    STOLEN: 'STOLEN',
};

export const convertReasonToStatus = reason => cardSatusValues[reason];

export const convertStatusToReason = status => {
    const statusReasonMap = {
        S: 'STOLEN',
        D: 'DAMAGED',
        E: 'EXPIRED',
    };
    return statusReasonMap[status];
};

export const cardPinErrors = {
    'CARD STATUS INVALID': 'Card is not active.',
    'ACCOUNT STATUS INVALID': 'Account is not active.',
    'CARD NOT FOUND': 'Card not found.',
    'MAX PIN TRIES EXCEEDED': 'Max PIN tries exceeded.',
    'MAX TOKEN TRIES EXCEEDED': 'Max token tried exceeded.',
    'INVALID TOKEN': 'Invalid token.',
    'INVALID CARDSERIAL': 'Internal system error.',
    'INVALID CVC2': 'Invalid CVC.',
    'INVALID CUSTOMER CODE': 'Internal system error.',
    'INVALID PIN': 'Invalid PIN.',
    'INVALID CUSTOMER': 'Internal system error.',
    'ERROR': 'Internal system error.',
}