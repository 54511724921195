import React from 'react';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import BulletPoint from 'components/shared/generic/bulletPoint/presentational/BulletPoint';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import { MORE, SUITS_ME_EMAIL, SUITS_ME_REFER } from 'constants/shared/routes';
import useTranslate from 'lib/languages/useTranslate';
import { useLocation } from 'react-router';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const ReferAFriend = () => {
    const translate = useTranslate('referAFriend');
    const location = useLocation();

    return (
        <PageContainer className="more-refer-a-friend-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={
                    location.state && location.state.backRoute
                        ? location.state.backRoute
                        : MORE
                }
            />
            <div className="refer-a-friend-container">
                <h2 className="subtitle">{translate('titleText')}</h2>
                <h2 className="headline">{translate('inviteText')}</h2>

                <Typography className="text">
                    {translate('bulletsInfoText')}
                </Typography>

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'inviteYourFriends',
                    )}</strong> ${translate('directFriends')}.`}
                />
                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'contactUs',
                    )}</strong> ${translate('sendEmail')}`}
                />
                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'earnMoney',
                    )}</strong> ${translate('earnInfo')}`}
                />
            </div>
            <div className="refer-a-friend-faqs-container">
                <h2 className="headline">{translate('referFAQS.title')}</h2>

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'referFAQS.howToTitle',
                    )}</strong> - ${translate('referFAQS.howToDescription')}.`}
                />

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'referFAQS.whenTitle',
                    )}</strong> - ${translate('referFAQS.whenDescription')}.`}
                />

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'referFAQS.howManyTitle',
                    )}</strong> - ${translate(
                        'referFAQS.howManyDescription',
                    )}.`}
                />

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'referFAQS.canITrackTitle',
                    )}</strong> - ${translate(
                        'referFAQS.canITrackDescription',
                    )}.`}
                />

                <BulletPoint
                    html
                    leftText={`<strong>${translate(
                        'referFAQS.canIReferTitle',
                    )}</strong> - ${translate(
                        'referFAQS.canIReferDescription',
                    )}.`}
                />
            </div>
            <ButtonContainer>
                <Button href={SUITS_ME_REFER}>{translate('moreInfo')}</Button>
                <Button href={SUITS_ME_EMAIL}>{translate('emailUs')}</Button>
            </ButtonContainer>
        </PageContainer>
    );
};

export default ReferAFriend;
