import { createAction } from '@reduxjs/toolkit';

export const twCreateTransactionHandleStaticFormChange = createAction(
    'TW_CREATE_TRANSACTION_HANDLE_STATIC_FORM_CHANGE',
);
export const twCreateTransactionHandleDynamicFormChange = createAction(
    'TW_CREATE_TRANSACTION_HANDLE_DYNAMIC_FORM_CHANGE',
);
export const twCreateTransactionRemoveDynamicField = createAction(
    'TW_CREATE_TRANSACTION_REMOVE_DYNAMIC_FIELD',
);
export const twCreateTransactionResetForm = createAction('TW_CREATE_TRANSACTION_RESET_FORM');
export const twCreateTransactionSetPostBody = createAction('TW_CREATE_TRANSACTION_SET_POST_BODY');
