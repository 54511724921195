import React from 'react';
import { isEmpty } from 'lib/utils';
import Box from 'components/shared/generic/box/presentational/Box';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Button from '../../button/presentational/Button';

const DataCheck = ({
    children,
    data,
    isLoading,
    error,
    errorMessage,
    noDataMessage = 'There is no data to display.',
    noDataClick,
    renderIfNotEmpty = false,
}) => {
    if (error) {
        return (
            <Box>
                <Typography className="data-check-error">
                    {errorMessage || error}
                </Typography>
            </Box>
        );
    }

    if (isLoading && isEmpty(data)) {
        return (
            <Box>
                <Typography className="data-check-loading loading-spinner">
                    <i className="far fa-spinner fa-spin" />
                </Typography>
            </Box>
        );
    }

    if (isEmpty(data) && !renderIfNotEmpty) {
        return (
            <Box>
                <Typography className="data-check-no-data">
                    {noDataMessage}
                </Typography>
                {noDataClick && <Button onClick={noDataClick}>Continue</Button>}
            </Box>
        );
    }

    return children;
};

export default DataCheck;
