import { createAction } from '@reduxjs/toolkit';
import { getTransferWiseLanguageCode } from '../selectors/languages';

import { api, handleErrors } from '../utils';
import { fetchAccount } from './account';

// twCreateTransaction
export const twCreateTransactionRequest = createAction('CREATE_TW_TRANSACTION_REQUEST');
export const twCreateTransactionSuccess = createAction('CREATE_TW_TRANSACTION_SUCCESS');
export const twCreateTransactionFailure = createAction('CREATE_TW_TRANSACTION_FAILURE');

export const twCreateTransaction = postBody => async (dispatch, getState) => {
    dispatch(twCreateTransactionRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(`/transferwise/transfers?language=${language}`, postBody);

        dispatch(twCreateTransactionSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twCreateTransactionFailure, e);
    }
};

// post transaction requiremnts
export const twPostTransactionRequirementsRequest = createAction(
    'POST_TRANSACTION_REQUIREMENTS_REQUEST',
);
export const twPostTransactionRequirementsSuccess = createAction(
    'POST_TRANSACTION_REQUIREMENTS_SUCCESS',
);
export const twPostTransactionRequirementsFailure = createAction(
    'POST_TRANSACTION_REQUIREMENTS_FAILURE',
);

export const twPostTransactionRequirements = postBody => async (dispatch, getState) => {
    dispatch(twPostTransactionRequirementsRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(
            `/transferwise/transfers/requirements?language=${language}`,
            postBody,
        );

        dispatch(twPostTransactionRequirementsSuccess(data));
        // dispatch(fetchAccount());
    } catch (e) {
        return handleErrors(dispatch, twPostTransactionRequirementsFailure, e);
    }
};

export const twClearTransactionsDetails = createAction('CLEAR_TW_TRANSACTIONS_DETAILS');
