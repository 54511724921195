import { formattedTransactions } from 'lib/utils/generic';
import dayjs from 'dayjs';

export const addFullNameTransactions = (arr, recipients) => {
    return arr.map(item => {
        return { ...item, fullName: recipients[item.targetAccount] };
    });
};

export const getListItems = transactions => {
    const formattedTransactionsObj = formattedTransactions(transactions);

    return Object.entries(formattedTransactionsObj)
        .sort(([a], [b]) => b.localeCompare(a))
        .reduce((acc, [date, transactionsByDate], index) => {
            const sectionHeading = dayjs(date).format('MMMM DD, YYYY');

            acc.push({
                key: sectionHeading,
                type: 'sectionHeader',
                text: sectionHeading,
                isFirstItem: index === 0,
            });

            transactionsByDate.forEach((transaction, i) => {
                acc.push({
                    key: `${i}-${transaction.createdDate}`,
                    type: 'transaction',
                    ...transaction,
                });
            });

            return acc;
        }, []);
};
