import { createAction } from '@reduxjs/toolkit';
import { api, handleErrors } from '../utils';

//Fetch PPS Direct Debits
export const fetchDirectDebitRequest = createAction('fetchDirectDebitRequest');
export const fetchDirectDebitSuccess = createAction('fetchDirectDebitSuccess');
export const fetchDirectDebitFailure = createAction('fetchDirectDebitFailure');

export const fetchDirectDebits = () => async dispatch => {
    dispatch(fetchDirectDebitRequest());

    try {
        const { data } = await api.get(`directdebit`);

        return dispatch(fetchDirectDebitSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, fetchDirectDebitFailure, e, true);
    }
};

export const cancelDirectDebitRequest = createAction('CANCEL_DIRECTDEBIT_REQUEST');
export const cancelDirectDebitSuccess = createAction('CANCEL_DIRECTDEBIT_SUCCESS');
export const cancelDirectDebitFailure = createAction('CANCEL_DIRECTDEBIT_FAILURE');

export const cancelDirectDebit = id => async dispatch => {
    dispatch(cancelDirectDebitRequest());

    try {
        await api.post(`directdebit/${id}`, {});
        dispatch(cancelDirectDebitSuccess({ id }));
    } catch (e) {
        if (e.response.data) {
            return dispatch(cancelDirectDebitFailure(e.response.data));
        } else {
            return handleErrors(dispatch, cancelDirectDebitFailure, e);
        }
    }
};
