import { languageCodes } from '../../../languages/constants';

import england from './en-gb.png';
import german from './de.png';
import french from './fr.png';
import bulgarian from './bg.png';
import romanian from './ro.png';
import polish from './pl.png';

export default {
    [languageCodes.ENGLISH]: england,
    [languageCodes.GERMAN]: german,
    [languageCodes.FRENCH]: french,
    [languageCodes.BULGARIAN]: bulgarian,
    [languageCodes.ROMANIAN]: romanian,
    [languageCodes.POLISH]: polish,
};
