export const getTransactionsFetchError = state => state.transactionsReducer.error;
export const getIsLastPage = state => state.transactionsReducer.isLastPage;
export const getNextPageStartDate = state => state.transactionsReducer.nextPageStartDate;
export const getTransactionsIsFetching = state => state.transactionsReducer.isFetching;
export const getTransactionPageNumber = state => state.transactionsReducer.pageNumber;

export const getTransactionsPostError = state => state.transactionsReducer.postError;
export const getTransactionsPostSuccess = state => state.transactionsReducer.postSuccess;
export const getTransactionsIsPosting = state => state.transactionsReducer.isPosting;

export const getTransaction = (state, index) => state.transactionsReducer.items[index];

export const getTransactionsList = state =>
    Object.values(state.transactionsReducer.items).sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createDate),
    );

export const getIsFetchingPendingTransactions = state => state.transactionsReducer.isFetchingPending;
export const getPendingTransactions = state => state.transactionsReducer.pendingItems;
export const getTotalAmountPending = state => state.transactionsReducer.totalAmountPending;
export const getTotalPendingTransactions = state => state.transactionsReducer.totalTransactionsPending;
export const getPendingError = state => state.transactionsReducer.pendingFetchError;
