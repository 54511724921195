//generics
export const ERROR_MODAL = 'ERROR_MODAL';
export const SUCCESS_MODAL = 'SUCCESS_MODAL';
export const PREMIUM_ACCOUNT_MODAL = 'PREMIUM_ACCOUNT_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const DISMISS_MODAL = 'DISMISS_MODAL';

// generic 5xx errors
export const GENERIC_5XX_ERROR_MODAL = 'GENERIC_5XX_ERROR_MODAL';

//auth
export const ENTER_CVC_MODAL = 'ENTER_CVC_MODAL';
export const ADD_NEW_CARDHOLDER = 'ADD_NEW_CARDHOLDER';
export const ADD_CARDHOLDER_DECLARATION = 'ADD_CARDHOLDER_DECLARATION';

export const STATUS_CARD_MODAL = 'STATUS_CARD_MODAL';
export const LSD_STATUS_CARD_MODAL = 'LSD_STATUS_CARD_MODAL';

export const ACCOUNT_BLOCKED_MODAL = 'ACCOUNT_BLOCKED_MODAL';

//payments
export const AUTHORIZE_PAYMENT_MODAL = 'AUTHORIZE_PAYMENT_MODAL';
export const SEARCH_AND_LIST_MODAL = 'SEARCH_AND_LIST_MODAL';
export const CONFIRM_AND_SEND_MODAL = 'CONFIRM_AND_SEND_MODAL';

// Subscription
export const REGRADE_SUBSCRIPTION_MODAL = 'REGRADE_SUBSCRIPTION_MODAL';
export const EXCESSIVE_FUNDS_MODAL = 'EXCESSIVE_FUNDS_MODAL';

// Statements
export const EXPORT_STATEMENT_MODAL = 'EXPORT_STATEMENT_MODAL';

//Account selection
export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';

// Fees
export const ADD_CARDHOLDER_FEE = 'ADD_CARDHOLDER_FEE';
export const REPLACEMENT_CARD_FEE = 'REPLACEMENT_CARD_FEE';
export const PAYMENT_FEE_MODAL = 'PAYMENT_FEE_MODAL';
export const STANDING_ORDER_FEE_MODAL = 'STANDING_ORDER_FEE_MODAL';
