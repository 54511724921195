import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'lib/languages/useTranslate';

const Activation = ({
    activationCode,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    const translate = useTranslate('activateAccount');

    return (
        <Box className="activation-container">
            <div className="statement-container">
                <SectionTitle title={translate('titleText')} secondary />
                <div className="statement mb">
                    <Typography>
                        {`${translate('descriptionText')} ${translate(
                            'infoText',
                        )}`}
                    </Typography>
                </div>
            </div>
            <Form
                // className="activation-form"
                submitText={translate('form.confirm')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? 'Something went wrong! Please try again.' : ''}
            >
                <Field required>
                    <TextInput
                        name="activationCode"
                        value={activationCode}
                        onChange={handleChange}
                        required
                        placeholder={translate('form.activationCode')}
                        classes="generic-input"
                    />
                </Field>
            </Form>
        </Box>
    );
};

export default Activation;
