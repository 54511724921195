import { createAction } from '@reduxjs/toolkit';
import { getTransferWiseLanguageCode } from '../selectors/languages';

import { api, formattedRecipients, handleErrors, twFormatDetails } from '../utils';

export const twSelectRecipient = createAction('TW_SELECT_RECIPIENT');
export const twClearRecipient = createAction('TW_CLEAR_RECIPIENT');

// Fetch recipients for transactions
export const twFetchTransactRecipientsRequest = createAction(
    'TW_FETCH_TRANSACT_RECIPIENTS_REQUEST',
);
export const twFetchTransactRecipientsSuccess = createAction(
    'TW_FETCH_TRANSACT_RECIPIENTS_SUCCESS',
);
export const twFetchTransactRecipientsFailure = createAction(
    'TW_FETCH_TRANSACT_RECIPIENTS_FAILURE',
);

export const twFetchTransactRecipients = () => async (dispatch, getState) => {
    dispatch(twFetchTransactRecipientsRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.get(`/transferwise/recipients?language=${language}`);

        return dispatch(twFetchTransactRecipientsSuccess(formattedRecipients(data)));
    } catch (e) {
        return handleErrors(dispatch, twFetchTransactRecipientsFailure, e, true);
    }
};

// Fetch recipients
export const twFetchRecipientsRequest = createAction('TW_FETCH_RECIPIENTS_REQUEST');
export const twFetchRecipientsSuccess = createAction('TW_FETCH_RECIPIENTS_SUCCESS');
export const twFetchRecipientsFailure = createAction('TW_FETCH_RECIPIENTS_FAILURE');

export const twFetchRecipients = currency => async (dispatch, getState) => {
    dispatch(twFetchRecipientsRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.get(
            `/transferwise/recipients?currency=${currency}&language=${language}`,
        );

        return dispatch(twFetchRecipientsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twFetchRecipientsFailure, e, true);
    }
};

// Fetch create recipient requirements
export const twFetchRecipientRequirementsRequest = createAction(
    'TW_FETCH_RECIPIENT_REQUIREMENTS_REQUEST',
);
export const twFetchRecipientRequirementsSuccess = createAction(
    'TW_FETCH_RECIPIENT_REQUIREMENTS_SUCCESS',
);
export const twFetchRecipientRequirementsFailure = createAction(
    'TW_FETCH_RECIPIENT_REQUIREMENTS_FAILURE',
);

export const twFetchRecipientRequirements = quoteID => async (dispatch, getState) => {
    dispatch(twFetchRecipientRequirementsRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.get(
            `/transferwise/recipients/requirements/${quoteID}?language=${language}`,
        );

        return dispatch(twFetchRecipientRequirementsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twFetchRecipientRequirementsFailure, e, true);
    }
};

// Fetch post recipient requirements
export const twPostRecipientRequirementsRequest = createAction(
    'TW_POST_RECIPIENT_REQUIREMENTS_REQUEST',
);
export const twPostRecipientRequirementsSuccess = createAction(
    'TW_POST_RECIPIENT_REQUIREMENTS_SUCCESS',
);
export const twPostRecipientRequirementsFailure = createAction(
    'TW_POST_RECIPIENT_REQUIREMENTS_FAILURE',
);

export const twPostRecipientRequirements = (quoteID, postBody) => async (dispatch, getState) => {
    dispatch(twPostRecipientRequirementsRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(
            `/transferwise/recipients/requirements/${quoteID}?language=${language}`,
            twFormatDetails(postBody),
        );

        return dispatch(twPostRecipientRequirementsSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twPostRecipientRequirementsFailure, e);
    }
};

// Create recipient
export const twCreateRecipientRequest = createAction('TW_CREATE_RECIPIENT_REQUEST');
export const twCreateRecipientSuccess = createAction('TW_CREATE_RECIPIENT_SUCCESS');
export const twCreateRecipientFailure = createAction('TW_CREATE_RECIPIENT_FAILURE');

export const twCreateRecipient = postBody => async (dispatch, getState) => {
    dispatch(twCreateRecipientRequest());

    const language = getTransferWiseLanguageCode(getState());
    try {
        const { data } = await api.post(
            `/transferwise/recipients?language=${language}`,
            twFormatDetails(postBody),
        );

        return dispatch(twCreateRecipientSuccess(data));
    } catch (e) {
        return handleErrors(dispatch, twCreateRecipientFailure, e);
    }
};
